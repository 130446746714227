<template>
  <v-container>
    <v-row>
      <v-col class="mt-14" cols="12">
        <h2>Settings</h2>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <plans :machines="data" :usedSpace="usedSpace"/>
    </template>
    <template v-if="this.userRoles && this.userRoles[0] !== 'ROLE_USER'">
      <pricing :usedSpace="usedSpace"/>
      <history  v-if="this.userRoles && this.userRoles[0] == 'ROLE_CUSTOMER'"/>
    </template>
    <template v-if="!loading">
      <machines :machines="data" />
    </template>

  </v-container>
</template>

<script>
import Machines from '@/components/settings/Machines.vue';
import Plans from '@/components/settings/Plans.vue';
import Pricing from '@/components/settings/Pricing.vue';
import History from '@/components/settings/History.vue';
import getMachines from '@/api/user/getMachines';
import getUsedSpace from '@/api/filemanager/getUsedSpace';

export default {
  name: 'SettingsPage',
  components: {
    Machines,
    Plans,
    Pricing,
    History,
  },
  data() {
    return {
      loading: true,
      data: [],
      usedSpace: 0,
      userRoles: null,
    };
  },
  created() {
    this.fetchData();
    this.fetchUsedSpace();
    this.userRoles = this.$store.getters['user/roles'];
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await getMachines()
        .then((response) => {
          this.data = response.data.map(
            (item, index) => ({
              ...item,
              index: index + 1,
            }),
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchUsedSpace() {
      getUsedSpace()
        .then((response) => {
          this.usedSpace = Math.round((response.data.size * 10) / (1024 * 1024 * 1024)) / 10;
        });
    },
  },
};
</script>
