function createZip(files, parentDir) {
  const data = {
    files,
    parentDir,
  };
  return window.$http({
    url: '/api/v1/filemanager/create-zip',
    method: 'post',
    data,
  });
}

export default createZip;
