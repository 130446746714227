function formatSize(num, MAX) {
  let suffix;
  let d = num;
  if ((d / 1073741824) >= 1) {
    d /= 1073741824;
    // if max values less than 20 GB
    if (MAX > 21474836480) {
      d = Math.ceil(d);
    } else {
      d = d.toFixed(2);
    }
    suffix = ' GB';
  } else if ((d / 1048576) >= 1) {
    d /= 1048576;
    if (MAX > 20971520) {
      d = Math.ceil(d);
    } else {
      d = d.toFixed(1);
    }
    suffix = ' MB';
  } else if ((d / 1024) >= 1) {
    d /= 1024;
    if (MAX > 20480) {
      d = Math.ceil(d);
    } else {
      d = d.toFixed(1);
    }
    suffix = ' KB';
  } else {
    d = Math.ceil(d);
    suffix = ' Bytes';
  }
  return d + suffix;
}

export default formatSize;
