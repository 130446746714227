<template>
    <v-card>
      <v-form @submit.prevent="onSaveItem" ref="form">
        <v-toolbar dense flat>
          <v-toolbar-title class="dialog-title">{{ $t('buy_credit.title') }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-text-field
            :label="$t('forms.company')"
            v-model="editedItem.name"
            class="mt-3"
            disabled
            :rules="$rules.required"
          ></v-text-field>
          <h4 class="my-3">{{ $t('buy_credit.orders') }}</h4>
            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
                indeterminate
                size="25"
                width="2"
                color="primary"
              ></v-progress-circular>
            </div>
          <template
            v-if="orderData && orderData.length">
            <v-expansion-panels class="order-list">
              <v-expansion-panel
                class="elevation-1"
                v-for="(item,i) in orderData"
                :key="i"
              >
                <v-expansion-panel-header>
                  <div class="order-list-data">
                    <span class="text--secondary mr-5">{{ format_date(item.created_at) }}</span>
                    <p class="mr-2 order-amount">{{item.amount}} &euro;</p>
                    <p>{{item.order_number}}</p>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="mb-0">Internal Notes:</p>
                  {{item.notes}}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          <div class="btn-text mb-5" @click="showNewOrder = true" v-if="!showNewOrder">
            {{ $t('buy_credit.new_order') }}
          </div>
          <v-row no-gutters v-if="showNewOrder">
            <v-col
              cols="12"
              sm="3"
            >
              <form-error :errors="formErrors.amount">
                <v-text-field
                  :label="$t('forms.order_euro')"
                  class="mt-3"
                  v-model="newOrder.amount"
                  :rules="$rules.required"
                ></v-text-field>
              </form-error>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              sm="8"
            >
              <form-error :errors="formErrors.amount">
                <v-text-field
                  :label="$t('forms.order_number')"
                  class="mt-3"
                  v-model="newOrder.order_number"
                  :rules="$rules.required"
                ></v-text-field>
              </form-error>
            </v-col>
            <v-col cols="12" sm="12">
              <h4 class="mt-12">{{ $t('buy_credit.internal') }}</h4>
              <v-textarea
                :label="$t('forms.notes')"
                v-model="newOrder.notes"
                :rules="$rules.required"
                class="mt-0"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" class="btn btn-radius-corner">{{ $t('forms.save') }}</v-btn>
            <v-btn @click="cancel" class="btn btn-gray btn-radius-corner">
              {{ $t('forms.cancel') }}</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
      <form-error/>
      <DialogConfirm ref="confirm" />
    </v-card>
</template>

<script>

import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
import FormError from '@/components/_common/FormError.vue';
import FormErrorMixin from '@/mixins/FormErrorMixin';
import getCompanyPayment from '@/api/admin/company/getCompanyPayment';
import createCompanyPayment from '@/api/admin/company/createCompanyPayment';
import DialogConfirm from '@/components/DialogConfirm.vue';

export default {
  name: 'BuyCreditDialog',
  components: {
    FormError,
    DialogConfirm,
  },
  mixins: [
    FormValidationRulesMixin,
    FormErrorMixin,
  ],
  props: {
    data: {
      default: null,
    },
  },
  data() {
    return {
      editedItem: {
        notes: '',
      },
      orderData: [],
      newOrder: {
        amount: '',
        order_number: '',
      },
      showNewOrder: false,
      loading: true,
    };
  },
  created() {
    this.editedItem = { ...this.editedItem, ...this.data };
    this.getPayment(this.editedItem.id);
  },
  methods: {
    getPayment(id) {
      getCompanyPayment(id)
        .then((response) => {
          this.orderData = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSaveItem() {
      if (this.$refs.form.validate()) {
        this.$refs.confirm.open('Save custom order', 'Save custom order?')
          .then((confirm) => {
            if (confirm) {
              createCompanyPayment(this.editedItem.id, this.newOrder)
                .then(() => {
                  this.closeDialog();
                })
                .finally(() => {
                  console.log('finihs');
                });
            }
          });
      }
    },
    closeDialog() {
      this.newOrder = {
        amount: '',
        notes: '',
        order_number: '',
      };
      this.cancel();
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-title {
  font-weight: bold;
}
.btn-text {
  color: #ce0f69;
  padding: 0;
  cursor: pointer;
  font-weight: 500;
}
.order-list {
  padding: 0;
  margin-bottom: 2rem;
  .v-expansion-panel {
    box-shadow: none!important;
    border-top: 1px solid rgba(169, 169, 169, 0.6);
    &:last-child {
      border-bottom: 1px solid rgba(169, 169, 169, 0.6);
    }
    .v-expansion-panel-header {
      padding: 0 1rem;
      .order-list-data {
        display: flex;
        align-items: center;
      p {
        margin-bottom: 0;
        }
      }
    }
    .v-expansion-panel-content {
      margin: 0 -0.5rem;
    }
  }
}
</style>
