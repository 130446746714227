<template>
  <v-container>
    <v-row>
      <v-col class="mt-14" cols="12">
        <h2>Settings</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>General</h3>
      </v-col>
    </v-row>
    <models />
    <v-row>
      <v-col class="mt-5" cols="12">
        <h3>AWS</h3>
      </v-col>
    </v-row>
    <instances />
  </v-container>
</template>

<script>
import Models from '@/components/settings/Models.vue';
import Instances from '@/components/adminsettings/Instances.vue';

export default {
  name: 'AdminSettingsPage',
  components: {
    Models,
    Instances,
  },
};
</script>
