<template>
  <div class="h-full">
    <v-container>
      <v-row>
        <v-col class="my-16" cols="12">
          <h2>Reports</h2>
          <h4>Review your billing details</h4>
        </v-col>

      </v-row>
    </v-container>
    <div class="bg-white py-5 h-full">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12">
            <div class="d-flex justify-space-between">
              <div class="d-flex">
                <div class="mr-3">
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="monthMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterMonth"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterMonth"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset()
                        * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                        :show-current="false"
                        type="month"
                        elevation="1"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div class="mr-3">
                  <v-select
                    v-model="selectedType"
                    :items="typeFilters"
                    itemText="name"
                    placeholder="Select Type"
                    itemValue="value"
                    @change="onChangeFilterType"
                  ></v-select>
                </div>
                <div>
                  <v-select
                    v-model="selectedStatus"
                    :items="allStatus"
                    itemText="name"
                    placeholder="Select Status"
                    itemValue="value"
                    disabled
                  ></v-select>
                </div>
              </div>
              <div>
                <v-btn
                  small
                  text
                  @click="resetFilter()"
                >clear filter</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12">
            <template>
              <v-data-table
                :headers="headers"
                :items="monthly_cost"
                class="elevation-1 billing-table"
                :loading="loading"
                :items-per-page="-1"
                :footer-props="{'items-per-page-options':[ 25, 50, 100, -1]}"
                disable-sort
              >
                <template v-slot:top>
                  <div class="d-flex justify-space-between">
                    <h3 class="card-title-table">Billing Details</h3>
                  </div>
                </template>
                <template v-slot:item.started_at="{ item }">
                  {{ format_date(item.started_at) }}
                </template>
                <template v-slot:item.finished_at="{ item }">
                  {{ format_date(item.finished_at, true) }}
                </template>
                <template v-slot:item.duration="{ item }">
                  {{ toHoursAndMinutes(item.duration) }}
                </template>
                <template v-slot:item.cost="{ item }">
                    {{item.cost}} &euro;
                </template>
                <template
                  v-slot:footer
                >
                  <div class="billing-detail-footer">
                    <p>{{ monthly_cost.length }} Trainings</p>
                    <div class="d-flex">
                      <p class="mr-5">{{ toHoursAndMinutes(allDuration) }}</p>
                      <p>{{ total_cost }} &euro;</p>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import getMonthlyCost from '@/api/user/getMonthlyCost';

export default {
  name: 'BillingDetailsPage',
  components: {
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: 'Created',
          align: 'start',
          value: 'started_at',
        },
        { text: 'Creator', value: 'creator' },
        { text: 'Title', value: 'title' },
        { text: 'Status', value: 'state' },
        { text: 'Finishing Date', value: 'finished_at' },
        { text: 'Duration', value: 'duration' },
        { text: 'Type', value: 'type' },
        { text: 'Cost', align: 'end', value: 'cost' },
      ],
      monthly_cost: [
        {
          started_at: '',
          creator: '',
          title: '',
          state: '',
          finished_at: '',
          duration: '',
          type: '',
          cost: '',
        },
      ],
      filterMonth: new Date(),
      monthMenu: false,
      selectedStatus: 'all',
      selectedType: '',
      allDuration: 0,
      total_cost: 0,
      allStatus: [
        { name: 'Aborted', value: 'aborted' },
        { name: 'Finished', value: 'finished' },
      ],
      typeFilters: [
        { name: 'Trainings', value: 'training' },
        { name: 'Testings', value: 'testing' },
      ],
    };
  },
  created() {
    this.getTodayDate(this.$route.query.month_year);
    this.selectedType = '';
  },
  methods: {
    resetFilter() {
      this.selectedStatus = 'all';
      this.selectedType = '';
      this.getTodayDate();
    },
    getTodayDate(paramDate = null) {
      this.filterMonth = new Date();
      if (paramDate) {
        this.filterMonth = new Date(paramDate);
      }
      const year = this.filterMonth.getFullYear();
      const month = this.filterMonth.getMonth() + 1;
      // eslint-disable-next-line
      this.filterMonth = year + '-' + month;

      this.fetchMonthlyCost(this.filterMonth);
    },
    save(date) {
      this.$refs.menu.save(date);
      this.fetchMonthlyCost(date);
    },
    async fetchMonthlyCost(fulldate) {
      this.allDuration = 0;
      this.total_cost = 0;
      const date = fulldate.split('-');
      const res = await getMonthlyCost(date[1], date[0]);
      this.monthly_cost = res.data;

      if (this.selectedType !== '') {
        this.monthly_cost = this.monthly_cost.filter(
          (item) => item.type === this.selectedType,
        );
      }
      if (res.data && res.data.length) {
        // eslint-disable-next-line
        for (const key in res.data) {
          this.allDuration += parseInt(res.data[key].duration, 0);
          this.total_cost += parseFloat(res.data[key].cost);
        }
        this.total_cost = this.total_cost.toFixed(2);
      }
      this.loading = false;
    },
    toHoursAndMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      if (hours > 0) {
        return `${hours} h ${minutes} min`;
      }
      return `${minutes} min`;
    },
    onChangeFilterType() {
      this.fetchMonthlyCost(this.filterMonth);
    },
  },
};
</script>
<style lang="scss" scoped>
.h-full {
  height: 100%;
}
.bg-white {
  background: white;
}
.card-title-table {
  font-size: 24px;
  font-weight: 500;
  padding: 1rem 1rem 1.5rem;
}
.billing-table > .v-data-table__wrapper > table > tbody > tr > td{
  padding: 1rem 16px;
}
.billing-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: 900;
  &:last-child {
    text-align: right!important;
  }
}
.billing-detail-footer {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  p {
    font-weight: bold
  }
}
</style>
