<template>
  <div>
    <v-progress-linear class="float-left mr-2 mb-1" height="25"
                       :color="progressBarColor"
                       :background-color="progressBarColor"
                       background-opacity="0.3"
                       :value="spaceUsedPercent"
                       :key="componentKey"
                       :class="progressBarClass">
      <v-progress-circular
        v-if="loadingUser || loadingUsedSpace"
        indeterminate
        size="15"
        color="white"
      ></v-progress-circular>
      <span v-else-if="volumeSize > 0" class="used-space-label">
        {{ usedSpace }}/{{ volumeSize }} GB used</span>
      <span v-else class="used-space-label">{{ usedSpace }} GB used</span>
    </v-progress-linear>
  </div>
</template>
<script>

import getUsedSpace from '../../api/filemanager/getUsedSpace';

export default {
  name: 'SpaceUsed',
  data() {
    return {
      volumeSize: 0,
      usedSpace: 0,
      currentCompany: null,
      loadingUser: true,
      loadingUsedSpace: true,
      intervall: null,
      componentKey: 0,
      fetchUsedSpaceIntervalId: null,
    };
  },
  created() {
    this.currentCompany = this.$store.getters['user/company'];
    setTimeout(() => {
      this.fetchUsedSpace();
    }, 2000);
    this.fetchUsedSpaceIntervalId = setInterval(() => {
      this.fetchUsedSpace();
    }, 300000);
  },
  mounted() {
    this.interval = setInterval(() => {
      this.checkUserApiRequestDone();
    }, 1000);
  },
  methods: {
    checkUserApiRequestDone() {
      this.loadingUser = true;
      if (this.currentCompany) {
        if (this.currentCompany.pricing.storage_space !== undefined) {
          this.volumeSize = this.currentCompany.pricing.storage_space
            + this.currentCompany.pricing.more_storage;
          clearInterval(this.interval);
        }
        this.loadingUser = false;
        this.componentKey += 1;
      }
    },
    fetchUsedSpace() {
      this.loadingUsedSpace = true;
      getUsedSpace()
        .then((response) => {
          this.usedSpace = Math.round((response.data.size * 10) / (1024 * 1024 * 1024)) / 10;
        })
        .finally(() => {
          this.loadingUsedSpace = false;
          this.componentKey += 1;
        });
    },
  },
  computed: {
    spaceUsedPercent() {
      if (this.volumeSize === 0) {
        return 0;
      }
      return Math.round((this.usedSpace * 100) / this.volumeSize);
    },
    progressBarColor() {
      let color = '#21BA45';
      this.$emit('exceedingStorageSpace', false);
      if (this.spaceUsedPercent >= 90) {
        color = '#FC9E00';
        this.$emit('exceedingStorageSpace', false);
      }
      if (this.spaceUsedPercent >= 100) {
        color = '#DB2828';
        this.$emit('exceedingStorageSpace', true);
      }
      return color;
    },
    progressBarClass() {
      let progressBarClass = 'space-used space-free-ok';
      if (this.spaceUsedPercent >= 90) {
        progressBarClass = 'space-used space-free-poor';
      }
      if (this.spaceUsedPercent >= 100) {
        progressBarClass = 'space-used space-free-none';
      }
      return progressBarClass;
    },
  },
  beforeDestroy() {
    clearInterval(this.fetchUsedSpaceIntervalId);
  },
};
</script>
<style lang="scss">
.space-used {
  border: 1px solid;
  &.space-free-ok {
    border-color: #21BA45;
  }
  &.space-free-poor {
    border-color: #FC9E00;
  }
  &.space-free-none {
    border-color: #DB2828;
  }
}
</style>
