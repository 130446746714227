<template>
  <v-row>
    <v-col class="mt-5" cols="12">
      <h3>{{ $t('settings.general') }}</h3>
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="data"
        disable-sort
         :disable-pagination="true"
        hide-default-footer
        class="elevation-1 machine-table"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="card-title-table">
              {{ $t('settings.machines.title') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="userRoles && userRoles[0] !== 'ROLE_USER'"
                  v-bind="attrs"
                  v-on="on"
                  @click="closeDialog()"
                >
                  <v-icon left>mdi-plus</v-icon>
                  New
                </v-btn>
              </template>
              <v-card>
                <v-form @submit.prevent="onSaveItem" @close="clear" ref="form" class="trainingform">
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-text-field
                      label="Title"
                      v-model="editedItem.title"
                      :rules="$rules.required"
                      class="mt-3"
                    ></v-text-field>
                    <v-textarea
                      label="Description"
                      v-model="editedItem.description"
                      :rules="$rules.required"
                      class="mt-6"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" class="btn btn-radius-corner">
                      {{ submitButtonTitle }}</v-btn>
                    <v-btn @click="closeDialog" class="btn btn-gray btn-radius-corner">
                      Cancel</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex flex-nowrap">
            <v-btn icon small @click="onEdit(item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon small @click="onDelete(item.id, item.title)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-col>
    <DialogConfirm ref="confirm"/>
  </v-row>
</template>

<script>
import DialogConfirm from '@/components/DialogConfirm.vue';
import getMachines from '@/api/user/getMachines';
import createMachine from '@/api/user/createMachine';
import updateMachine from '@/api/user/updateMachine';
import deleteMachine from '@/api/user/deleteMachine';

export default {
  name: 'Machines',
  components: {
    DialogConfirm,
  },
  computed: {
    isEditing() {
      return this.editedIndex !== -1;
    },
    dialogTitle() {
      return this.editedIndex === -1 ? 'New machine' : 'Edit machine';
    },
    submitButtonTitle() {
      return this.editedIndex === -1 ? 'Create machine' : 'Update machine';
    },
  },
  props: {
    machines: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
      userRoles: null,
      headers: [
        { text: '#', value: 'index' },
        { text: 'Title', value: 'title' },
        { text: 'Description', value: 'description' },
      ],
      dialog: false,
      loading: true,
      editedIndex: -1,
      totalItems: 0,
      editedItem: {
        title: '',
        description: '',
      },
      defaultItem: {
        title: '',
        description: '',
      },
    };
  },
  created() {
    // check if array Machines has items, if not call api to get machines.
    if (this.machines && this.machines.length) {
      this.totalItems = this.machines.length;
      this.data = this.machines;
      this.loading = false;
    } else {
      this.fetchData();
    }
    // check and hide action column if user_role is user
    this.userRoles = this.$store.getters['user/roles'];
    if (this.userRoles && this.userRoles[0] !== 'ROLE_USER') {
      this.headers.push({ text: 'Actions', value: 'actions' });
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      getMachines()
        .then((response) => {
          this.data = response.data.map(
            (item, index) => ({
              ...item,
              index: index + 1,
            }),
          );
          this.totalItems = response.data.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSaveItem() {
      if (!this.$refs.form.validate()) return;

      if (this.editedIndex > -1) {
        this.$store.dispatch('dialogloader/show', 'The update is in progress ...');
        updateMachine(this.editedItem)
          .then(() => {
            Object.assign(this.data[this.editedIndex], this.editedItem);
            this.closeDialog();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'));
      } else {
        this.$store.dispatch('dialogloader/show', 'Adding in progress...');

        createMachine(this.editedItem)
          .then(() => {
            this.fetchData();
            this.closeDialog();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'));
      }
    },
    onEdit(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = { ...item };

      this.dialog = true;
    },
    onDelete(id, title) {
      this.$refs.confirm.open('Delete machine', `Do you really want to delete machine ${id} - ${title}?`)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'The machine is being deleted...');
            deleteMachine(id)
              .then(() => {
                this.fetchData();
                this.closeDialog();
              })
              .finally(() => this.$store.dispatch('dialogloader/hide'));
          }
        });
    },
    closeDialog() {
      this.clearDialog();
    },
    clearDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<style lang="scss">
.machine-table {
  > .v-data-table__wrapper > table > tbody > tr > td{
    padding: 1rem 16px;
  }
  .v-toolbar__content {
    padding-left: 0;
  }
}
</style>
