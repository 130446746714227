function cancelUpload(fileUniqueId) {
  const data = {
    fileUniqueId,
  };
  return window.$http({
    url: '/api/v1/filemanager/cancel-upload',
    method: 'post',
    data,
  });
}

export default cancelUpload;
