function updateMachine(machine) {
  const { id } = machine;
  const data = {
    title: machine.title,
    description: machine.description,
  };
  return window.$http({
    url: `/api/v1/machine/${id}`,
    method: 'put',
    data,
  });
}

export default updateMachine;
