<template>
  <div>
    <v-row class="justify-start">
      <v-col cols="10">
        <h3>{{ $t('company.company_form.general') }}</h3>
      </v-col>
    </v-row>
    <v-text-field
      label="Customer number"
      v-model="editedItem.customer_number"
      :rules="$rules.required"
    ></v-text-field>
    <form-error :errors="formErrors.customer_number" />
    <v-text-field
      label="Name"
      v-model="editedItem.name"
      autofocus
      :rules="$rules.required"
    ></v-text-field>
    <form-error :errors="formErrors.name" />
    <v-text-field
      label="Street"
      v-model="editedItem.street"
      :rules="$rules.required"
    ></v-text-field>
    <form-error :errors="formErrors.street" />
    <v-text-field
      label="Street number"
      v-model="editedItem.street_no"
      value=""
    ></v-text-field>
    <form-error :errors="formErrors.street_no" />
    <v-text-field
      label="Zip"
      v-model="editedItem.zip"
      :rules="$rules.required && $rules.postcode"
    ></v-text-field>
    <form-error :errors="formErrors.zip" />
    <v-text-field
      label="City"
      v-model="editedItem.city"
      :rules="$rules.required"
    ></v-text-field>
    <form-error :errors="formErrors.city" />
    <v-select
      label="Country"
      :items="countries"
      v-model="editedItem.country"
      :rules="$rules.required"
    ></v-select>
    <form-error :errors="formErrors.country" />
    <!--plans section-->
    <plan-list
      :key="planListKey"
      :editedItem="editedItem"
      :isEditing="isEditing"
      :formErrors="formErrors"
      :disableOldPrice="hideNewPlan"
       @delete="deleteFuturePlan" />
    <v-row class="justify-start mt-8">
      <template v-if="isEditing && hideNewPlan">
        <v-col cols="12">
          <h3>{{ $t('company.company_form.plan') }}</h3>
        </v-col>
        <v-col cols="12" sm="12">
          <v-row>
            <v-col cols="12" sm="6">
            <div>
              <v-btn class="start-training-btn" small
              @click="newPricing = true; initialDataForNewPlan()">
                New Plan
              </v-btn>
              <a type="button" v-if="newPricing"
                  @click="hideNewPricingPlan()" class="ml-3">
                Delete New Plan</a>
            </div>
            </v-col>
            <v-col cols="12" sm="12" v-if="newPricing">
              <NewPricing :items="new_pricing" :formErrors="formErrors"
                    :isEditing="isEditing"/>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <v-col cols="12" sm="12">
        <h4 class="subtitle-1 mb-5">{{ $t('company.company_form.customer_pricing') }}</h4>
        <div>
          <p class="mb-0">Basic fee: {{ customerPricing.basic_fee
            ? customerPricing.basic_fee.toFixed(2) : 0 }} Euro</p>
          <p class="mb-0">Extension fee: {{ customerPricing.extension_fee
            ? customerPricing.extension_fee.toFixed(2) : 0 }} Euro</p>
          <p class="mb-0">Final fee: {{ customerPricing.final_fee
            ? customerPricing.final_fee.toFixed(2) : 0}} Euro</p>
          <p class="mb-0">{{ customerPricing.usedItem
            ? customerPricing.usedItem : 0 }}</p>
        </div>
      </v-col>
    </v-row>

   <div class="mb-5 mt-8">
     <v-row class="justify-start">
       <v-col cols="10">
         <h3>AWS Bucket</h3>
       </v-col>
     </v-row>
     <v-autocomplete
       clearable
       v-model="editedItem.aws_bucket"
       :items="buckets"
       :loading="isBucketsLoading"
       cache-items
       :label="editedItem.aws_bucket"
       persistent-hint
        class="mt-0"
       :rules="$rules.required"
       :disabled="isEditing"
     >
     </v-autocomplete>
     <form-error :errors="formErrors.aws_bucket" />
   </div>
    <DialogConfirm ref="confirm" />
    </div>
</template>

<script>

import FormError from '@/components/_common/FormError.vue';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
import NewPricing from '@/components/companies/NewPricing.vue';
import PlanList from '@/components/companies/PlanList.vue';
import deleteCompanyPricing from '@/api/admin/company/deleteCompanyPricing';
import DialogConfirm from '@/components/DialogConfirm.vue';
import countries from '@/variables/countries';

export default {
  name: 'CompanyForm',
  components: {
    FormError,
    NewPricing,
    PlanList,
    DialogConfirm,
  },
  mixins: [
    FormValidationRulesMixin,
  ],
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    buckets: {
      type: Array,
    },
    isBucketsLoading: {
      type: Boolean,
    },
    formErrors: {
      type: Object,
    },
    isEditing: {
      type: Boolean,
    },
  },
  data() {
    return {
      newPricing: false,
      hideNewPlan: true,
      planListKey: 1,
      customerPricing: {
        basic_fee: 0,
        extension_fee: 0,
        final_fee: 0,
        usedItem: '',
      },
      new_pricing: {
        id: this.editedItem.id,
        valid_from: null,
        seats: null,
        storage_space: null,
        training_minutes: null,
        basic_fee_per_month: null,
        cost_per_minute: null,
        more_user: null,
        cost_for_more_user: null,
        more_storage: null,
        cost_for_more_storage: null,
        more_minutes: null,
        cost_for_more_minutes: null,
        more_gpus: null,
        cost_for_more_gpus: null,

      },
      countries,
    };
  },
  mounted() {
    this.calculateCustomerPricing();
  },
  methods: {
    calculateCustomerPricing() {
      this.customerPricing.basic_fee = parseFloat(this.editedItem.pricing.basic_fee_per_month
        ? this.editedItem.pricing.basic_fee_per_month : 0);
      this.calculateExtensionFee();
      this.customerPricing.final_fee = this.customerPricing.extension_fee !== null
        ? this.customerPricing.basic_fee
        + this.customerPricing.extension_fee : 0;
      const seats = parseFloat(this.editedItem.pricing.seats
        ? this.editedItem.pricing.seats : 0)
        + parseFloat(this.editedItem.pricing.more_user
          ? this.editedItem.pricing.more_user : 0);
      const storage = parseFloat(this.editedItem.pricing.storage_space
        ? this.editedItem.pricing.storage_space : 0)
        + parseFloat(this.editedItem.pricing.more_storage
          ? this.editedItem.pricing.more_storage : 0);
      const minutes = parseFloat(this.editedItem.pricing.training_minutes
        ? this.editedItem.pricing.training_minutes : 0)
        + parseFloat(this.editedItem.pricing.more_minutes
          ? this.editedItem.pricing.more_minutes : 0);
      // eslint-disable-next-line
      this.customerPricing.usedItem = 'Using ' + seats + ' seats, '
        + storage + ' GB, ' + minutes + ' minutes';
    },
    calculateExtensionFee() {
      const lengthOfPricing = Object.keys(this.editedItem.pricing).length;
      const costOfUser = lengthOfPricing > 0 ? this.editedItem.pricing.more_user
        * this.editedItem.pricing.cost_for_more_user : 0;
      const costOfMinutes = lengthOfPricing > 0
        ? (this.editedItem.pricing.more_minutes / 1000)
        * this.editedItem.pricing.cost_for_more_minutes : 0;
      const costOfGpu = lengthOfPricing > 0
        ? this.editedItem.pricing.more_gpus
        * this.editedItem.pricing.cost_for_more_gpus : 0;
      const costOfStorage = lengthOfPricing > 0
        ? (this.editedItem.pricing.more_storage / 100)
        * this.editedItem.pricing.cost_for_more_storage : 0;
      this.customerPricing.extension_fee = costOfUser + costOfMinutes + costOfStorage + costOfGpu;
    },
    initialDataForNewPlan() {
      // add values to new pricing inputs
      const keys = Object.keys(this.new_pricing);
      for (let i = 0; i < keys.length; i += 1) {
        if (this.editedItem.futurePricing) {
          this.new_pricing[keys[i]] = this.editedItem.futurePricing[keys[i]];
        } else this.new_pricing[keys[i]] = this.editedItem.pricing[keys[i]];
      }
      this.new_pricing.id = this.editedItem.id;
    },
    deleteFuturePlan(id) {
      this.$refs.confirm.open('Future Pricing Plan', 'Delete future Pricing Plan?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'The pricing plan is being deleted...');
            deleteCompanyPricing(id)
              .then(() => {
                this.planListKey += 1;
                this.editedItem.futurePricing = null;
              })
              .finally(() => {
                this.hideNewPlan = true;
                this.$store.dispatch('dialogloader/hide');
              });
          }
        });
    },
    hideNewPricingPlan() {
      this.newPricing = false;
      this.initialDataForNewPlan();
    },
  },
  watch: {
    editedItem: {
      deep: true,
      handler() {
        this.calculateCustomerPricing();
      },
    },
  },
};
</script>
