function updateCompany(company) {
  const { id } = company;
  const pricingObj = {
    seats: company.pricing.seats,
    storage_space: company.pricing.storage_space,
    training_minutes: company.pricing.training_minutes,
    basic_fee_per_month: company.pricing.basic_fee_per_month,
    cost_per_minute: company.pricing.cost_per_minute,
    more_user: company.pricing.more_user,
    cost_for_more_user: company.pricing.cost_for_more_user,
    more_storage: company.pricing.more_storage,
    cost_for_more_storage: company.pricing.cost_for_more_storage,
    more_minutes: company.pricing.more_minutes,
    cost_for_more_minutes: company.pricing.cost_for_more_minutes,
    more_gpus: company.pricing.more_gpus,
    cost_for_more_gpus: company.pricing.cost_for_more_gpus,
  };
  const data = {
    name: company.name,
    customer_number: company.customer_number,
    street: company.street,
    street_no: company.street_no,
    zip: company.zip,
    city: company.city,
    country: company.country,
  };
  if (!id) {
    data.pricing = pricingObj;
  }
  return window.$http({
    url: `/api/v1/admin/company/${id}`,
    method: 'put',
    data,
  });
}

export default updateCompany;
