function updateCurrentPricing(item) {
  const { id } = item;
  const data = {
    more_user: item.pricing.more_user,
    more_storage: item.pricing.more_storage,
    more_minutes: item.pricing.more_minutes,
    more_gpus: item.pricing.more_gpus,
  };
  console.log('aaaa price update', data);
  return window.$http({
    url: `/api/v1/admin/company/${id}/edit-current-pricing`,
    method: 'put',
    data,
  });
}

export default updateCurrentPricing;
