<template>
  <v-container>
    <v-row>
      <v-col class="mt-14" cols="10" sm="4">
        <h2>{{ $t('company.companies') }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="companies"
          :server-items-length="totalItems"
          :page.sync="page"
           :disable-pagination="true"
          @update:page="onPageUpdated"
          :loading="loading"
          disable-sort
          hide-default-footer
          class="elevation-1 extra-space-table"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('company.companies_list') }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="closeDialog()"
                  >
                    <v-icon left>mdi-office-building-outline</v-icon>
                    Add
                  </v-btn>
                </template>

                <v-card>
                  <v-form @submit.prevent="onSaveItem" ref="form">
                    <v-toolbar dense flat>
                      <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                      <company-form
                        :editedItem="editedItem"
                        :buckets="buckets"
                        :isBucketsLoading="isBucketsLoading"
                        :formErrors="formErrors"
                        :isEditing="isEditing"
                        ref="companyForm"
                      />

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" class="btn btn-radius-corner">Save</v-btn>
                        <v-btn @click="closeDialog" class="btn btn-gray btn-radius-corner">
                          Cancel</v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-sm-center flex-nowrap">
              <v-switch
                class="tabel-switch"
                v-model="item.active"
                @change="onChangeActive(item)"
              ></v-switch>
              <v-btn icon small @click="onBuyCredit(item)">
                <v-icon small>mdi-cart</v-icon>
              </v-btn>
              <v-btn icon small @click="onEdit(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon small @click="onDelete(item.id)" disabled>
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <dialog-confirm ref="confirm"/>
    <v-dialog v-model="openBuyCreditDialog" max-width="600" @keydown.esc="closeBuyDialog">
      <dialog-buy-credit
        v-if="openBuyCreditDialog"
        :data="openBuyItem"
        ref="buy-credit"
        @close="closeBuyDialog()" />
    </v-dialog>
  </v-container>
</template>

<script>
import CompanyForm from '@/components/companies/CompanyForm.vue';
import DialogBuyCredit from '@/components/companies/DialogBuyCredit.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';
import getAllCompanies from '@/api/admin/company/getAllCompanies';
import deleteCompany from '@/api/admin/company/deleteCompany';
import updateCompany from '@/api/admin/company/updateCompany';
import updateCurrentPricing from '@/api/admin/company/updateCurrentPricing';
import getCompanyPricings from '@/api/admin/company/getCompanyPricings';
import newCurrentPricing from '@/api/admin/company/newCurrentPricing';
import createCompany from '@/api/admin/company/createCompany';
import getAllBuckets from '@/api/admin/company/getAllBuckets';
import FormErrorMixin from '@/mixins/FormErrorMixin';
import changeCompanyState from '@/api/admin/company/changeCompanyState';

export default {
  name: 'CompaniesPage',
  components: {
    CompanyForm,
    DialogConfirm,
    DialogBuyCredit,
  },
  mixins: [FormErrorMixin],
  props: {
    isActive: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      activeCompany: true,
      page: 1,
      dialog: false,
      openBuyCreditDialog: false,
      editedIndex: -1,
      totalItems: 0,
      openBuyItem: null,
      editedItem: {
        id: '',
        name: '',
        customer_number: '',
        street: '',
        street_no: '',
        zip: '',
        city: '',
        credit: 0,
        pricing: {},
        futurePricing: null,
        order_number: '',
        aws_bucket: '',
        addon_labeling: false,
        addon_ai_test: false,
        active: true,
      },
      defaultItem: {
        id: null,
        name: null,
        customer_number: null,
        street: null,
        street_no: '',
        zip: null,
        city: null,
        aws_bucket: '',
        max_user_number: 10,
        max_bucket_space: 100,
        max_training_minutes: 100,
        pricing: {},
        futurePricing: null,
        addon_labeling: false,
        addon_ai_test: false,
        active: true,
      },
      companies: [],
      loading: true,
      buckets: [],
      isBucketsLoading: false,
      filter: [],
      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', value: 'name' },
        { text: 'Zip', value: 'zip' },
        { text: 'City', value: 'city' },
        { text: 'Seats', value: 'seatTotal' },
        { text: 'Storage', value: 'storageTotal' },
        { text: 'Minutes', value: 'minuteTotal' },
        { text: 'AWS Bucket', value: 'aws_bucket' },
        { text: 'Actions', value: 'actions' },
      ],
      initialItem: {},
      initialPrice: {},
      initialFuturePrice: null,
    };
  },
  created() {
    this.fetchCompanies();
    this.fetchBuckets();
  },
  methods: {
    onPageUpdated() {
      this.loading = true;
      this.fetchCompanies();
    },
    fetchCompanies() {
      getAllCompanies()
        .then((response) => {
          this.companies = response.data.data.map(
            (item, index) => ({
              ...item,
              index: index + 1,
              seatTotal: item.pricing ? item.pricing.seats + item.pricing.more_user : 0,
              storageTotal: item.pricing
                ? item.pricing.storage_space + item.pricing.more_storage : 0,
              minuteTotal: item.pricing
                ? item.pricing.training_minutes + item.pricing.more_minutes : 0,
              active: !item.deactivated_at,
            }),
          );
          this.totalItems = response.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchBuckets() {
      getAllBuckets()
        .then((response) => {
          const buckets = response.data;
          this.companies.forEach((company) => {
            const index = buckets.indexOf(company.aws_bucket);
            if (index !== -1) {
              buckets.splice(index, 1);
            }
          });
          this.buckets = buckets;
        })
        .finally(() => {
          this.isBucketsLoading = false;
        });
    },
    onEdit(item) {
      this.editedIndex = this.companies.indexOf(item);
      this.editedItem = { ...item };
      this.initialItem = { ...this.editedItem };
      getCompanyPricings(this.editedItem.id)
        .then((response) => {
          this.editedItem.pricing = response.data.current_pricing;
          this.editedItem.futurePricing = response.data.future_pricing;
        })
        .finally(() => {
          if (!this.editedItem.pricing || Object.keys(this.editedItem).length === 0) {
            this.editedItem.pricing = {
              seats: null,
              storage_space: null,
              training_minutes: null,
              basic_fee_per_month: null,
              cost_per_minute: null,
              more_user: null,
              cost_for_more_user: null,
              more_storage: null,
              cost_for_more_storage: null,
              more_minutes: null,
              cost_for_more_minutes: null,
              cost_for_more_gpus: null,
              more_gpus: null,
            };
          }
          this.initialPrice = { ...this.editedItem.pricing };
          this.initialFuturePrice = { ...this.editedItem.futurePricing };
          this.dialog = true;
          setTimeout(() => {
            // this is to show new plan button or hide
            if (this.editedItem.futurePricing) {
              this.$refs.companyForm.hideNewPlan = false;
            } else {
              this.$refs.companyForm.hideNewPlan = true;
            }
          }, 100);
        });
    },
    async onSaveItem() {
      if (!this.$refs.form.validate()) return;
      if (this.editedIndex > -1) {
        this.$store.dispatch('dialogloader/show', 'The update is in progress ...');
        // if company has new pricing plan
        if (this.$refs.companyForm.newPricing) {
          this.$refs.companyForm.new_pricing.valid_from += '-01';
          await newCurrentPricing(this.editedItem.id, this.$refs.companyForm.new_pricing)
            .catch((err) => {
              this.$store.dispatch('notificationbar/showNotification',
                { msg: err.response.data[0], color: 'error', show: true });
              this.mapSymfonyErrors(err.response.data.errors);
            });
        }
        if (this.editedItem.futurePricing
          && this.checkIfDataHasChanged(this.editedItem.futurePricing, this.initialFuturePrice)) {
          await newCurrentPricing(this.editedItem.id, this.editedItem.futurePricing)
            .catch((err) => {
              this.$store.dispatch('notificationbar/showNotification',
                { msg: err.response.data[0], color: 'error', show: true });
              this.mapSymfonyErrors(err.response.data.errors);
            });
        }
        if (this.checkIfDataHasChanged(this.editedItem, this.initialItem)) {
          await updateCompany(this.editedItem)
            .then(() => {
            })
            .finally(() => this.$store.dispatch('dialogloader/hide'))
            .catch((err) => {
              this.$store.dispatch('notificationbar/showNotification',
                { msg: err.response.data[0], color: 'error', show: true });
              this.mapSymfonyErrors(err.response.data.errors);
            });
        }
        if (this.checkIfDataHasChanged(this.editedItem.pricing, this.initialPrice)) {
          await updateCurrentPricing(this.editedItem)
            .catch((err) => {
              this.$store.dispatch('notificationbar/showNotification',
                { msg: err.response.data[0], color: 'error', show: true });
              this.mapSymfonyErrors(err.response.data.errors);
            });
        }
        this.fetchCompanies();
        this.$store.dispatch('dialogloader/hide');
        this.closeDialog();
      } else {
        this.$store.dispatch('dialogloader/show', 'Adding in progress...');
        createCompany(this.editedItem)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.companies.unshift(this.editedItem);
            this.updateCompanyFields();
            this.closeDialog();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'))
          .catch((err) => {
            this.$store.dispatch('notificationbar/showNotification', { msg: err.response.data[0], color: 'error', show: true });
            this.mapSymfonyErrors(err.response.data.errors);
          });
      }
      this.$refs.companyForm.initialDataForNewPlan();
      this.$refs.companyForm.newPricing = false;
    },
    onDelete(id) {
      this.$refs.confirm.open('Delete company', 'Delete company?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'The company is being deleted...');

            deleteCompany(id)
              .then((response) => {
                const index = this.companies.findIndex((item) => item.id === id);
                this.$delete(this.companies, index);

                this.$store.dispatch('dialogpopup/show', {
                  title: response.data.title,
                  message: response.data.message,
                });
              })
              .finally(() => this.$store.dispatch('dialogloader/hide'));
          }
        });
    },
    closeDialog() {
      this.clearDialog();
    },
    clearDialog() {
      this.dialog = false;
      if (this.$refs.companyForm) {
        this.$refs.companyForm.newPricing = false;
      }
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
      this.formErrors = {};
      if (this.$refs.form.resetValidation()) {
        this.$refs.form.resetValidation();
      }
    },
    onBuyCredit(item) {
      this.openBuyCreditDialog = true;
      this.openBuyItem = item;
    },
    closeBuyDialog() {
      this.openBuyCreditDialog = false;
    },
    onChangeActive(item) {
      let activeCompany = 1;
      let confirmTitle = 'Activate company';
      let confirmMessage = `Activate company ${item.name} and all ${item.seatTotal} users?`;
      let messageStateString = 'activated';
      // switched to inactive state
      if (item.active === false) {
        activeCompany = 0;
        confirmTitle = 'Deactivate company';
        confirmMessage = `Deactivate company ${item.name} and all ${item.seatTotal} users?`;
        messageStateString = 'deactivated';
      }
      this.$refs.confirm.open(confirmTitle, confirmMessage)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', `The company is being ${messageStateString}...`);
            changeCompanyState(item.id, activeCompany)
              .then(() => {
                const msg = `Company ${item.name}${item.active === false ? ' deactivated' : ' activated'}`;
                this.$store.dispatch('notificationbar/showNotification', {
                  msg,
                  color: 'success',
                  show: true,
                });
              })
              .finally(() => this.$store.dispatch('dialogloader/hide'));
          } else {
            // eslint-disable-next-line no-param-reassign
            item.active = !item.active;
            this.closeDialog();
          }
        });
    },
    updateCompanyFields() {
      this.companies = this.companies.map(
        (item, index) => ({
          ...item,
          index: index + 1,
          seatTotal: item.pricing ? parseInt(item.pricing.seats, 0)
            + parseInt(item.pricing.more_user, 0) : 0,
          storageTotal: item.pricing
            ? parseInt(item.pricing.storage_space, 0) + parseInt(item.pricing.more_storage, 0) : 0,
          minuteTotal: item.pricing
            ? parseInt(item.pricing.training_minutes, 0)
            + parseInt(item.pricing.more_minutes, 0) : 0,
        }),
      );
      this.totalItems = this.companies.length;
    },
    checkIfDataHasChanged(obj, data) {
      if (obj) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          if (obj[key] !== data[key] && typeof obj[key] === 'string') {
            return true;
          }
        }
        return false;
      }
      return false;
    },
  },
  computed: {
    isEditing() {
      return this.editedIndex !== -1;
    },
    dialogTitle() {
      return this.editedIndex === -1 ? this.$t('company.add_company') : this.$t('company.edit_company');
    },
    itemsWithIndex() {
      return this.companies.map(
        (item, index) => ({
          ...item,
          index: index + 1,
        }),
      );
    },
  },
};
</script>
<style lang="scss">
.extra-space-table > .v-data-table__wrapper > table > tbody > tr > td{
  padding: 1rem 16px;
}
.tabel-switch{
  margin: 0;
  .v-input__control {
    .v-input__slot {
      margin: 0;
    }
    .v-messages{
      display: none;
    }
  }
}
</style>
