function fileList(path, start = 1) {
  const data = {
    path: encodeURIComponent(path),
    start,
  };
  return window.$http({
    url: `/api/v1/filemanager/file-list?path=${data.path}&start=${data.start}`,
    method: 'get',
  });
}

export default fileList;
