function dirAdd(dirname, parentPath) {
  const data = {
    dirname,
    parentPath,
  };
  return window.$http({
    url: '/api/v1/filemanager/dir-add',
    method: 'post',
    data,
  });
}

export default dirAdd;
