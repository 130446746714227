<template>
  <!-- File UPLOAD DIALOGUE start -->
  <el-dialog v-cloak
             title="Upload"
             :visible.sync="localFileUploadDialog"
             @close="closeLocalUploadDialog()">
    <p>Please select file or folder upload</p>
    <div class="clearfix"
         style="display:flex;align-items:center;margin-bottom:10px">
      <div class="upload-btn-wrapper"
           v-if="isUploadDirSupported"
           style="margin-right:20px">
        <el-button type="primary"
                   @click="openFolderUploadDialog()" :disabled="exceedingStorageSpace"
        >Upload Folder
        </el-button>
        <input type="file"
               id="folder"
               ref="folderUploadInputButton"
               webkitdirectory
               multiple
               @change="addFolderToUpload()"/>
      </div>
      <div class="upload-btn-wrapper">
        <el-button type="primary"
                   @click="openFileUploadDialog()" :disabled="exceedingStorageSpace"
        >Upload Files
        </el-button>
        <input type="file"
               id="file"
               ref="fileUploadInputButton"
               multiple
               @change="addFilesToUpload()"/>
      </div>
      <div style="display:block;align-items:center;width:100%;">
      <div class="clearfix"
           style="display:flex;align-items:center;margin-left:10px">
        <div style="flex: 1 1"></div>
        <div :span="24"
             v-if="filesToUpload && filesToUpload.length > 0"><b><span style="margin-right:5px">
        {{ filesUploaded }} of {{ filesToUpload.length }}</span><span>files uploaded</span>
        </b></div>
        <div style="flex: 1 1"></div>
        <div v-if="filesToUpload && filesToUpload.length > 1"><span
          style="margin-right:5px;font-weight:bold">
        {{ formatSize(filesToUploadInfo.uploaded) }} / {{ formatSize(filesToUploadInfo.totalSize) }}
      </span>
        </div>
      </div>
      <div class="clearfix"
           style="display:flex;align-items:center;margin-left:10px">
        <div style="flex: 1 1"></div>
        <div
          v-if="filesToUpload && filesToUpload.length > 0">
          <b>
            <span>Time elapsed:</span>
            <span
              style="margin-right:5px;">
              {{ formatSeconds(Math.floor(Date.now() / 1000)
              - filesToUploadInfo.started).toString() }}
              @ {{ formatSize(filesToUploadInfo.speed) }}/s
            </span>
          </b>
        </div>
      </div>
      </div>
    </div>

    <div v-if="!isMobile"
         style="max-height:250px;overflow-y:auto;overflow-x:hidden">
      <div :span="24" v-if="loading">
        <v-progress-circular
          indeterminate
          size="15"
          color="black"
        ></v-progress-circular>
      </div>
      <div class="row-bg" v-if="filesToUpload.length > 10">
        <div :span="24" v-if="filesToUploadInfo.totalSize > 0">
          <el-progress
            :percentage="Math.floor((filesToUploadInfo.uploaded/filesToUploadInfo.totalSize)
            * 100)">
          </el-progress>
        </div>
      </div>
      <div class="row-bg" v-else
           v-for="(fileInfo, index) in filesToUpload"
           :key="index"
           style="margin-bottom:5px">
        <div :span="24" v-if="!fileInfo.isUploaded() && fileInfo.progressComputed > 0"
             class="clearfix flex">
          <div style="font-weight: 500">{{ fileInfo.file.name }}</div>
          <div style="flex: 1 1"></div>
          <div>{{ fileInfo.uploadInfoText }}</div>
          <div
               style="margin-left: 10px;">
            <el-button v-if="fileInfo.isCanceled"
                       type="text"
                       title="Canceled"><v-icon>mdi-stop</v-icon></el-button>
            <el-button v-else
                       @click="cancelFileUpload(fileInfo)"
                       type="text"
                       title="Cancel"><img src="@/assets/icons/close-24px.svg"
                                           alt="Cancel"></el-button>
          </div>
        </div>
        <div :span="24" v-if="!fileInfo.isUploaded() && fileInfo.progressComputed > 0">
          <el-progress
                       :percentage="fileInfo.progressComputed"></el-progress>
        </div>
      </div>
    </div>

    <div v-if="isMobile"
         style="max-height:180px;overflow-y:auto;overflow-x:hidden">
      <div class="row-bg"
           v-for="(fileInfo, index) in filesToUpload"
           :key="index"
           style="margin-bottom:10px">
        <div :span="24"
             style="font-weight: 500">{{ fileInfo.file.name }}
        </div>
        <div :span="24"
             class="flex">
          <div>{{ fileInfo.uploadInfoText }}</div>
          <div style="flex: 1 1"></div>
          <div v-if="!fileInfo.isUploaded()"
               style="margin-left: 10px;">
            <el-button @click="cancelFileUpload(fileInfo)"
                       type="text"
                       title="Cancel Uploading"><img src="@/assets/icons/close-24px.svg"
                                                     alt="Cancel"></el-button>
          </div>
        </div>
        <div :span="24">
          <el-progress
                       :percentage="fileInfo.progressComputed"></el-progress>
        </div>
      </div>
    </div>

    <div v-if="isMobile"
         style="align-items:center;margin-bottom:10px;margin-top:15px;">
      <div :span="24"
           v-if="filesToUpload && filesToUpload.length > 0 && filesUploaded < filesToUpload.length
            && filesToUploadInfo.timeRemaining"><span style="margin-right:5px;font-weight:bold">
        Time elapsed :
          {{ formatSeconds(Math.floor(Date.now() / 1000)
          - filesToUploadInfo.started).toString() }}
          @ {{ formatSize(filesToUploadInfo.speed) }}/s</span>
      </div>
      <div :span="24"
           v-if="filesToUpload && filesToUpload.length > 1">
        <span style="margin-right:5px;font-weight:bold">Uploaded :
    {{ formatSize(filesToUploadInfo.uploaded) }} / {{ formatSize(filesToUploadInfo.totalSize) }}
        </span>
      </div>
    </div>
    <span v-if="!isMobile && threadsRunning > 0"
          slot="footer"
          class="dialog-footer"><el-button type="primary" class="mr-2"
                                           @click="stopAndClearUpload()">Cancel upload</el-button>
    </span>
    <span v-if="!isMobile"
          slot="footer"
          class="dialog-footer"><el-button type="primary"
                                           @click="closeLocalUploadDialog()">Close</el-button>
    </span>
    <dialog-confirm ref="confirm" />
  </el-dialog>
  <!-- File UPLOAD DIALOGUE end -->
</template>

<script>

import DialogConfirm from '@/components/DialogConfirm.vue';
import elementLocale from 'element-ui/lib/locale';
import elementLangEn from 'element-ui/lib/locale/lang/en';
import cancelUpload from '@/api/filemanager/cancelUpload';
import formatSize from '@/helper/formatSize';

export default {
  name: 'FileUploader',
  components: {
    DialogConfirm,
  },
  props: {
    bus: {
      type: Object,
      required: false,
    },
    currentDir: {
      type: String,
      required: false,
    },
    exceedingStorageSpace: {
      type: Boolean,
    },
  },
  data() {
    return {
      bearer: null,
      localFileUploadDialog: false,
      speedArray: [],
      maxThreads: 20,
      threadsRunning: 0,
      filesToUpload: [],
      isUploadDirSupported: false,
      filesToUploadInfo: {
        started: null,
        totalSize: 0,
        uploaded: 0,
        timeRemaining: 0,
        speed: 0,
      },
      loading: false,
    };
  },
  watch: {
    threadsRunning() {
      this.addThread();
    },
  },
  computed: {
    filesUploaded() {
      let count = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const fileToUpload of this.filesToUpload) {
        if (fileToUpload.isUploaded()) {
          count += 1;
        }
      }
      return count;
    },
    isMobile() {
      // eslint-disable-next-line no-restricted-globals
      return screen.width <= 720;
    },
  },
  created() {
    elementLocale.use(elementLangEn);
    const bearer = this.$store.getters['user/token'];
    this.bearer = bearer.replace('bearer ', '');
    this.isUploadDirSupported = !this.isMobile && this.isInputDirSupported();
    this.addNetworkCheckerListeners();
  },

  mounted() {
    this.bus.$on('openLocalUploadDialog', this.openLocalUploadDialog);
  },

  methods: {
    refresh() {
      this.$emit('refresh');
    },
    openFileUploadDialog() {
      this.$refs.fileUploadInputButton.click();
    },

    openFolderUploadDialog() {
      this.$refs.folderUploadInputButton.click();
    },

    isInputDirSupported() {
      const tmpInput = document.createElement('input');
      if ('webkitdirectory' in tmpInput
        || 'mozdirectory' in tmpInput
        || 'odirectory' in tmpInput
        || 'msdirectory' in tmpInput
        || 'directory' in tmpInput) return true;
      return false;
    },

    openLocalUploadDialog() {
      this.localFileUploadDialog = true;
    },

    pauseFileUpload(fileInfo) {
      const threadsPaused = fileInfo.pause();
      if (threadsPaused > this.threadsRunning) {
        this.threadsRunning = 0;
      } else {
        this.threadsRunning -= threadsPaused;
      }
    },

    cancelFileUpload(fileInfo) {
      const threadsCanceled = fileInfo.cancel();
      if (threadsCanceled > this.threadsRunning) {
        this.threadsRunning = 0;
      } else {
        this.threadsRunning -= threadsCanceled;
      }
      let i = this.filesToUpload.length;
      while (i < 0) {
        const obj = this.filesToUpload[i];
        if (obj.file.name === fileInfo.file.name && obj.parentDir === fileInfo.parentDir) {
          this.filesToUpload.splice(i, 1);
          break;
        }
        i -= 1;
      }
      this.filesToUploadInfo.totalSize -= fileInfo.file.size;
      this.filesToUploadInfo.uploaded -= fileInfo.bytesUploaded;
      this.filesToUploadInfo.speed = 0;
      if (this.filesToUploadInfo.totalSize === 0) {
        this.speedArray = [];
      }
    },

    resumeFileUpload(fileInfo) {
      fileInfo.resume();
      this.addThread();
    },

    clear() {
      this.filesToUploadInfo.totalSize = 0;
      this.filesToUploadInfo.uploaded = 0;
      this.filesToUploadInfo.speed = 0;
      this.filesToUploadInfo.timeRemaining = '';
      this.filesToUploadInfo.started = null;
      this.filesToUpload = [];
      this.speedArray = [];
    },

    createFileInfoObject(file) {
      const { size } = file;
      const bytesPerChunk = 5242880;
      const totalNoOfChunks = Math.max(Math.ceil(size / bytesPerChunk), 1);
      const fileUniqueId = file.name + (new Date()).getTime();
      let parentDir = this.currentDir;
      if (file.webkitRelativePath) {
        let webkitpath = file.webkitRelativePath;
        webkitpath = webkitpath.substring(0, webkitpath.lastIndexOf('/'));
        if (!parentDir.endsWith('/')) {
          parentDir = `${parentDir}/${webkitpath}`;
        } else {
          parentDir += webkitpath;
        }
      }
      // eslint-disable-next-line no-param-reassign
      file.parentDir = parentDir;
      return {
        bearer: this.bearer,
        apiUrl: process.env.VUE_APP_ROOT_API ? process.env.VUE_APP_ROOT_API.trim().replace(/\/+$/, '') : '',
        parentDir,
        file,
        bytesPerChunk,
        totalNoOfChunks,
        chunksUploaded: 0,
        chunksUploading: 0,
        bytesUploaded: 0,
        isPaused: false,
        isCanceled: false,
        hasNetworkError: false,
        threads: {},
        progressComputed: 0,
        fileUniqueId,
        uploadInfoText: `- / ${formatSize(file.size, file.size)}`,
        canCreatePromise() {
          return (this.chunksUploaded + this.chunksUploading) !== this.totalNoOfChunks
            && !this.isPaused && !this.isCanceled;
        },
        isUploaded() {
          return this.chunksUploaded === this.totalNoOfChunks;
        },
        createPromise() {
          const start = (this.chunksUploaded + this.chunksUploading) * this.bytesPerChunk;
          const end = start + this.bytesPerChunk;
          const blob = this.file.slice(start, end);
          const currentChunkSize = (this.file.size < end ? this.file.size : end) - start;
          const chunkToUpload = this.chunksUploaded + this.chunksUploading + 1;
          return new Promise((resolve, reject) => {
            const fd = new FormData();
            const xhr = new XMLHttpRequest();
            fd.append('upload', blob, this.file.name);
            fd.append('fileUniqueId', this.fileUniqueId);
            fd.append('num', chunkToUpload);
            fd.append('num_chunks', this.totalNoOfChunks);
            fd.append('parentDir', this.parentDir);
            xhr.open('POST', `${this.apiUrl}/api/v1/filemanager/upload`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${this.bearer}`);
            this.threads[chunkToUpload] = {};
            this.threads[chunkToUpload].progress = 0.0;
            this.threads[chunkToUpload].stop = () => {
              xhr.abort();
            };
            this.threads[chunkToUpload].currentChunkSize = currentChunkSize;
            xhr.upload.addEventListener('progress', (evt) => {
              if (evt.lengthComputable) {
                this.threads[chunkToUpload].progress = evt.loaded / evt.total;
                this.progressComputed = this.progress();
                this.uploadInfoText = `${formatSize(this.bytesUploadedTillNow(), this.file.size)} / ${formatSize(this.file.size, this.file.size)}`;
              }
            }, false);
            this.chunksUploading += 1;
            const date = new Date();
            xhr.onload = () => resolve({
              fileInfo: this,
              index: chunkToUpload,
              currentChunkSize,
              time: date.getTime(),
            });

            xhr.onerror = () => {
              this.pause();
              reject(xhr.statusText);
            };
            xhr.send(fd);
          });
        },
        pause() {
          this.isPaused = true;
          let threadsStopped = 0;
          let minKey = Number.MAX_VALUE;
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const sKey in this.threads) {
            this.threads[sKey].stop();
            threadsStopped += 1;
            delete this.threads[sKey];
            const key = parseInt(sKey, 10);
            if (minKey > key) {
              minKey = key;
            }
          }
          this.chunksUploaded = minKey - 1;
          this.chunksUploading = 0;
          this.bytesUploaded = this.chunksUploaded * this.bytesPerChunk;
          this.progressComputed = this.progress();
          this.uploadInfoText = `${formatSize(this.bytesUploadedTillNow(), this.file.size)} / ${formatSize(this.file.size, this.file.size)}`;
          return threadsStopped;
        },
        cancel() {
          this.isPaused = true;
          this.isCanceled = true;
          let threadsStopped = 0;
          const sKeys = Object.keys(this.threads);
          sKeys.forEach((sKey) => {
            this.threads[sKey].stop();
            threadsStopped += 1;
          });
          this.threads = {};
          this.chunksUploading = 0;
          this.progressComputed = 0;
          const fileUniqueIdThis = this.fileUniqueId;
          cancelUpload(fileUniqueIdThis)
            .then((response) => {
              console.log(response);
            }).catch((e) => {
              console.error(e);
            });
          return threadsStopped;
        },
        resume() {
          this.isPaused = false;
        },
        progress() {
          let { chunksUploaded } = this;
          const sKeys = Object.keys(this.threads);
          sKeys.forEach((sKey) => {
            chunksUploaded += this.threads[sKey].progress;
          });
          return parseInt((chunksUploaded * 100) / this.totalNoOfChunks, 10);
        },
        bytesUploadedTillNow() {
          let { bytesUploaded } = this;
          const sKeys = Object.keys(this.threads);
          sKeys.forEach((sKey) => {
            bytesUploaded += this.threads[sKey].progress * this.threads[sKey].currentChunkSize;
          });
          return parseInt(bytesUploaded, 10);
        },
      };
    },

    submitFiles() {
      this.addThread();
    },

    addNetworkCheckerListeners() {
      window.addEventListener('offline', () => {
        if (this.filesToUpload.length <= 0) {
          return;
        }
        let anyUploadingStopped = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileInfo of this.filesToUpload) {
          if (fileInfo.isUploaded() || fileInfo.isPaused || fileInfo.isCanceled) {
            // eslint-disable-next-line no-continue
            continue;
          }
          anyUploadingStopped = true;
          fileInfo.pause();
          fileInfo.hasNetworkError = true;
        }
        const msg = `Network disconnected${anyUploadingStopped ? '. Pausing file(s) uploading.' : ''}`;
        this.$store.dispatch('notificationbar/showNotification', {
          msg,
          color: 'error',
          show: true,
        });
        this.threadsRunning = 0;
      });
      window.addEventListener('online', () => {
        if (this.filesToUpload.length <= 0) {
          return;
        }
        let anyUploadingRestarted = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const fileInfo of this.filesToUpload) {
          if (fileInfo.isUploaded() || !fileInfo.hasNetworkError) {
            // eslint-disable-next-line no-continue
            continue;
          }
          anyUploadingRestarted = true;
          fileInfo.resume();
          fileInfo.hasNetworkError = false;
        }
        const msg = `Network connected.${anyUploadingRestarted ? ', Resuming file(s) uploading.' : ''}`;
        this.$store.dispatch('notificationbar/showNotification', {
          msg,
          color: 'info',
          show: true,
        });
        this.addThread();
      });
    },

    closeLocalUploadDialog() {
      const uploadSizeEqual = this.filesToUploadInfo.totalSize === this.filesToUploadInfo.uploaded;
      const allChunksUploaded = this.chunksUploaded === this.totalNoOfChunks;
      this.refresh();
      this.localFileUploadDialog = false;
      if (uploadSizeEqual && allChunksUploaded) {
        this.clear();
      }
    },
    stopAndClearUpload() {
      this.$refs.confirm.open('Cancel upload', 'Cancel all uploads and flush file queue?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'The upload is canceled...');
            this.clear();
            this.$store.dispatch('dialogloader/hide');
          }
        });
    },
    addThread() {
      if (this.threadsRunning < this.maxThreads && this.filesToUpload.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const fileToUpload of this.filesToUpload) {
          if (fileToUpload.canCreatePromise()) {
            fileToUpload.createPromise().then((info) => {
              const obj = info.fileInfo;
              obj.chunksUploaded += 1;
              obj.chunksUploading -= 1;
              obj.bytesUploaded += info.currentChunkSize;
              this.filesToUploadInfo.uploaded += info.currentChunkSize;

              // calculating speed speedArray
              this.speedArray.push((info.currentChunkSize * 1000)
                / ((new Date()).getTime() - info.time));
              if (this.speedArray.length > 8) {
                this.speedArray.splice(0, 1);
              }
              this.filesToUploadInfo.speed = Math.floor(this.filesToUploadInfo.uploaded
              / (Math.floor(Date.now() / 1000) - this.filesToUploadInfo.started));
              this.threadsRunning -= 1;
              delete obj.threads[info.index];
            }).catch(() => {
              this.threadsRunning -= 1;
            });
            this.threadsRunning += 1;
            if (this.threadsRunning >= this.maxThreads) return;
          }
        }
      }
    },

    addFolderToUpload() {
      if (this.filesToUploadInfo.started === null) {
        this.filesToUploadInfo.started = Math.floor(Date.now() / 1000);
      }
      this.loading = true;
      this.showUploadProgress = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const file of this.$refs.folderUploadInputButton.files) {
        this.filesToUploadInfo.totalSize += file.size;
        this.filesToUpload.push(this.createFileInfoObject(file));
      }
      this.addThread();
      this.loading = false;
    },

    addFilesToUpload() {
      if (this.filesToUploadInfo.started === null) {
        this.filesToUploadInfo.started = Math.floor(Date.now() / 1000);
      }
      this.loading = true;
      this.showUploadProgress = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const file of this.$refs.fileUploadInputButton.files) {
        this.filesToUploadInfo.totalSize += file.size;
        this.filesToUpload.push(this.createFileInfoObject(file));
      }
      if (this.$refs.folderUploadInputButton) {
        this.$refs.folderUploadInputButton.value = '';
      }
      if (this.$refs.fileUploadInputButton) {
        this.$refs.fileUploadInputButton.value = '';
      }
      this.addThread();
      this.loading = false;
    },
    formatSize(num, MAX) {
      return formatSize(num, MAX);
    },
    formatSeconds(diff) {
      return {
        getDays() {
          return Math.floor((diff / (60 * 60 * 24)));
        },
        getHours() {
          return Math.floor(((diff - this.getDays() * 60 * 60 * 24) / (60 * 60)));
        },
        getMinutes() {
          return Math.floor(((diff - (this.getDays() * 60 * 60 * 24
            + this.getHours() * 60 * 60))
            / (60)));
        },
        getSeconds() {
          return Math.floor(diff - (this.getDays() * 60 * 60 * 24
            + this.getHours() * 60 * 60 + this.getMinutes() * 60));
        },
        toString() {
          const days = this.getDays() > 0 ? `${this.getDays()} days` : '';
          const hours = this.getHours() > 0 ? `${this.getHours()} hours` : '';
          const minutes = this.getMinutes() > 0 ? `${this.getMinutes()} minutes` : '';
          const seconds = this.getSeconds() > 0 ? `${this.getSeconds()} seconds` : '0 seconds';
          return `${days} ${hours} ${minutes} ${seconds}`;
        },
      };
    },
  },
};
</script>
