<template>
  <div class="reports-dashboard">
    <v-container>
      <v-row>
        <v-col cols="12" class="my-16">
          <h2>Reports</h2>
          <h4>Customer Reports</h4>
        </v-col>
      </v-row>
    </v-container>
    <div class="bg-white py-5 h-50">
      <v-container>
        <v-row class="mt-15 pb-10">
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="box-card elevation-1">
              <div class="box-card__header">
                <h3>Customer billing history</h3>
              </div>
              <v-card-text class="py-5 px-5">
                <div class="text--primary">
                  Overview of billing activities and plan changes
                </div>
              </v-card-text>
              <v-card-actions class="mb-2 px-5">
                <v-btn class="btn primary billing-btn" to="/reports/billing-history">Details</v-btn>
              </v-card-actions>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="box-card elevation-1">
              <div class="box-card__header">
                <h3>Customer billing details</h3>
              </div>
              <v-card-text class="py-5 px-5">
                <div class="text--primary">
                  Monthly billing details with training/testing minutes
                </div>
              </v-card-text>
              <v-card-actions class="mb-2 px-5">
                <v-btn class="btn primary billing-btn" to="/reports/billing-details">Details</v-btn>
              </v-card-actions>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardPage',
};
</script>
<style lang="scss" scoped>
.bg-white {
  background: white;
}
.btn.primary.billing-btn {
  padding: .5rem 2rem;
}
.h-50 {
  min-height: 50vh;
}
.reports-dashboard .box-card {
  min-height: 200px;
  position: relative;
}
.reports-dashboard .v-card__actions {
  position: absolute;
  bottom: 0;
}
</style>
