function getSize(files, parentDir) {
  const data = {
    files,
    parentDir,
  };
  return window.$http({
    url: '/api/v1/filemanager/size',
    method: 'post',
    data,
  });
}

export default getSize;
