<template>
  <v-row >
    <v-col class="mt-5" cols="12">
      <h3>{{ $t('settings.plans.title') }}</h3>
    </v-col>
    <v-col
      v-if="isPrimary && !isOnlyUser"
      cols="12"
      md="4"
      sm="12">
        <v-sheet :elevation="1" rounded class="pa-5 h-100">
          <h3 class="card-title">{{ $t('settings.plans.seats') }}</h3>
          <p>{{ $t('settings.plans.used_paid') }} {{ $t('settings.plans.seats') }}</p>
         <div  class="mb-5">
           <div class="d-flex align-baseline">
             <h2 class="unit-title">
               {{usersLength}}/{{this.currentCompany.pricing.seats
             + this.currentCompany.pricing.more_user}}
             </h2>
             <p class="unit ml-3">{{ $t('settings.plans.users') }}</p>
             <a href="mailto:support@vision-cockpit.com" class="manage-link ml-3"
             v-if="this.userRoles && this.userRoles[0] !== 'ROLE_USER'">
               {{ $t('settings.plans.manage') }}</a>
           </div>
           <small>{{ $t('settings.plans.available_seats') }}</small>
         </div>
        </v-sheet>
    </v-col>
    <v-col
      cols="12"
      md="4"
      sm="12">
      <v-sheet :elevation="1" rounded class="pa-5 h-100">
        <h3 class="card-title">{{ $t('settings.plans.storage') }}</h3>
        <p>{{ $t('settings.plans.used_paid') }} {{ $t('settings.plans.storage') }}</p>
        <div  class="mb-5">
          <div class="d-flex align-baseline">
            <h2 class="unit-title">{{usedSpace}}/{{ volumeSize }}</h2>
            <p class="unit ml-3">{{ $t('settings.plans.gb') }}</p>
            <a href="mailto:support@vision-cockpit.com" class="manage-link ml-3"
               v-if="this.userRoles && this.userRoles[0] !== 'ROLE_USER'">
              {{ $t('settings.plans.manage') }}</a>
          </div>
          <small>{{ $t('settings.plans.available_storage') }}</small>
        </div>
      </v-sheet>
    </v-col><v-col
    cols="12"
    md="4"
    sm="12">
    <v-sheet :elevation="1" rounded class="pa-5 h-100">
      <h3 class="card-title">{{ $t('settings.plans.minutesLong') }}</h3>
      <p>
        {{ $t('settings.plans.used_paid') }} {{ $t('settings.plans.minutesLong').toLowerCase() }}
      </p>
      <div  class="mb-5">
        <div class="d-flex align-baseline">
          <h2 class="unit-title">{{usedMinutes}}/{{this.currentCompany.pricing.training_minutes
          + this.currentCompany.pricing.more_minutes}}</h2>
          <p class="unit ml-3">{{ $t('settings.plans.minutes') }}</p>
          <a href="mailto:support@vision-cockpit.com" class="manage-link ml-3"
             v-if="this.userRoles && this.userRoles[0] !== 'ROLE_USER'">
            {{ $t('settings.plans.manage') }}</a>
        </div>
        <small>{{ $t('settings.plans.available_training') }}</small>
      </div>
    </v-sheet>
  </v-col>
  </v-row>
</template>

<script>
import getMachines from '@/api/user/getMachines';
import getCompanyUsers from '@/api/customerUser/getCompanyUsers';
import calculateMinutes from '@/api/user/calculateMinutes';

export default {
  name: 'Plans',
  data() {
    return {
      data: [],
      visibility: true,
      volumeSize: null,
      usersLength: null,
      currentCompany: null,
      usedMinutes: null,
      date: new Date(),
      userRoles: null,
    };
  },
  props: {
    machines: {
      type: Array,
      default: () => [],
    },
    usedSpace: {
      default: null,
      type: Number,
    },
  },
  created() {
    // check if array Machines has items, if not call api to get machines.
    if (this.machines && this.machines.length) {
      this.totalItems = this.machines.length;
      this.data = this.machines;
      this.loading = false;
    } else {
      this.fetchData();
    }
    this.userRoles = this.$store.getters['user/roles'];
    this.currentCompany = this.$store.getters['user/company'];
    this.mainCompany = this.$store.getters['user/mainCompany'];
    this.volumeSize = this.currentCompany.pricing.storage_space
      + this.currentCompany.pricing.more_storage;
    if (this.currentCompany.id === this.mainCompany.id
      && this.userRoles[0] !== 'ROLE_USER') {
      this.fetchUsers();
    }
    this.fetchUsedMinutes();
  },
  computed: {
    isPrimary: {
      get() { return this.currentCompany.id === this.mainCompany.id; },
    },
    isOnlyUser: {
      get() { return this.userRoles && this.userRoles[0] === 'ROLE_USER'; },
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      getMachines()
        .then((response) => {
          this.data = response.data.map(
            (item, index) => ({
              ...item,
              index: index + 1,
            }),
          );
          this.totalItems = response.data.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchUsers() {
      getCompanyUsers()
        .then((response) => {
          this.usersLength = response.data.total;
        });
    },
    fetchUsedMinutes() {
      calculateMinutes(this.date.getMonth() + 1, this.date.getFullYear())
        .then((response) => {
          this.usedMinutes = response.data.minutes;
        });
    },
  },
};
</script>
<style scoped lang="scss">
  .card-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  .manage-link, small, .unit {
    font-size: 14px;
  }
  .unit{
    font-weight: 500;
  }
  .unit-title {
    font-size: 32px;
  }
  .h-100 {
    height: 100%;
  }
</style>
