<template>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="itemsWithIndex"
          disable-sort
          :disable-pagination="true"
          hide-default-footer
          class="elevation-1 instances"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Training Instances</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="closeDialog()"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form
                    @submit.prevent="onSaveItem"
                    @close="clearDialog"
                    ref="form"
                    class="instanceform"
                  >
                    <v-toolbar dense flat>
                      <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-row class="justify-start mt-5">
                        <v-col cols="10">
                          <h3>
                            Instance information
                          </h3>
                        </v-col>
                      </v-row>
                      <v-text-field
                        label="Instance ID"
                        v-model="editedItem.instanceId"
                        :rules="$rules.required"
                        class="mt-3"
                      ></v-text-field>
                      <v-text-field
                        label="GPU Parameter"
                        v-model="editedItem.gpuParameter"
                        maxlength="50"
                        :rules="[rules.maxLength(50)]"
                        class="mt-3"
                        counter
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn type="submit" class="btn btn-radius-corner">
                        {{ submitButtonTitle }}</v-btn>
                      <v-btn @click="closeDialog" class="btn btn-gray btn-radius-corner">
                        Cancel</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.state="{ item }">
            <div class="state" :class="item.state">{{ item.state }}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-sm-center flex-nowrap">
              <v-switch
                class="table-switch"
                @change="onChangeActive(item)"
                v-model="item.active"
                :disabled="item.state !== 'stopped'"
              ></v-switch>
              <v-btn icon small @click="onDelete(item)" :disabled="item.state !== 'stopped'">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <DialogConfirm ref="confirm"/>
    </v-row>
</template>

<script>
import DialogConfirm from '@/components/DialogConfirm.vue';
import getInstances from '@/api/aws/getInstances';
import addInstance from '@/api/admin/settings/addInstance';
import updateInstance from '@/api/admin/settings/updateInstance';
import deleteInstance from '@/api/admin/settings/deleteInstance';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
import FormError from '@/components/_common/FormError.vue';

export default {
  name: 'Instances',
  components: {
    DialogConfirm,
  },
  mixins: [
    FormValidationRulesMixin,
    FormError,
  ],
  data() {
    return {
      data: [],
      dialog: false,
      loading: true,
      interval: '',
      defaultItem: {
        instanceId: '',
        gpuParameter: '',
      },
      editedItem: {
        instanceId: '',
        gpuParameter: '',
      },
      headers: [
        { text: '#', value: 'index' },
        { text: 'Name', value: 'name' },
        { text: 'Instance ID', value: 'instance_id' },
        { text: 'GPU Parameter', value: 'gpu_parameter' },
        { text: 'State', value: 'state' },
        { text: 'Actions', value: 'actions' },
      ],
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.fetchData();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getInstances()
        .then((response) => {
          this.data = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async onSaveItem() {
      if (!this.$refs.form.validate()) return;
      try {
        await this.$store.dispatch('dialogloader/show', 'Adding new instance...');
        await addInstance(this.editedItem);
        const msg = 'The instance has been added';

        await this.$store.dispatch('notificationbar/showNotification', {
          msg,
          color: 'success',
          show: true,
        });
        this.fetchData();
        this.closeDialog();
      } catch (error) {
        console.log('An error occurred while adding instance: ', error);
        await this.$store.dispatch('notificationbar/showNotification', {
          msg: error.response.data.errors.errors[0],
          color: 'error',
          show: true,
        });
        this.closeDialog();
      } finally {
        await this.$store.dispatch('dialogloader/hide');
        this.closeDialog();
      }
    },
    onEdit(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = { ...item };

      this.dialog = true;
    },
    onDelete(item) {
      this.$refs.confirm.open('Delete instance', 'Do you really want to delete this instance in the database ?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'Deleting instance...');
            deleteInstance(item)
              .then(() => {
                const msg = 'The instance has been deleted';
                this.$store.dispatch('notificationbar/showNotification', {
                  msg,
                  color: 'success',
                  show: true,
                });
                this.fetchData();
              })
              .catch((error) => {
                console.error('An error occurred while deleting the instance:', error);
                this.$store.dispatch('notificationbar/showNotification', {
                  msg: error.response.data,
                  color: 'error',
                  show: true,
                });
              })
              .finally(() => {
                this.$store.dispatch('dialogloader/hide');
                this.fetchData();
              });
          }
        });
    },
    async onChangeActive(item) {
      const { id, name } = item;
      const active = !item.active;
      const updateState = active ? 'Deactivate' : 'Activate';

      try {
        const confirm = await this.$refs.confirm.open(
          `${updateState} instance`,
          `Do you really want to ${updateState.toLowerCase()} the instance ${name} ?`,
        );

        if (confirm) {
          this.$store.dispatch('dialogloader/show', 'The instance is being updated...');
          try {
            await updateInstance(id, item.active);
            const msg = `The instance "${name}" has been ${updateState.toLowerCase()}d`;
            this.$store.dispatch('notificationbar/showNotification', {
              msg,
              color: 'success',
              show: true,
            });
          } catch (error) {
            console.error('An error occurred while updating the instance:', error);
            this.$store.dispatch('notificationbar/showNotification', {
              msg: error.response.data[0],
              color: 'error',
              show: true,
            });
          } finally {
            this.$store.dispatch('dialogloader/hide');
            this.fetchData();
          }
        } else {
          this.fetchData();
          this.closeDialog();
        }
      } catch (error) {
        console.error('An error occurred while confirming the update:', error);
        this.$store.dispatch('notificationbar/showNotification', {
          msg: error.message,
          color: 'error',
          show: true,
        });
      }
    },
    closeDialog() {
      this.clearDialog();
    },
    clearDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        if (this.$refs.form) this.$refs.form.resetValidation();
      });
    },
  },
  computed: {
    itemsWithIndex() {
      return this.data.map(
        (item, index) => ({
          ...item,
          index: index + 1,
        }),
      );
    },
    dialogTitle() {
      return 'Add instance';
    },
    submitButtonTitle() {
      return 'Add instance';
    },
  },
};
</script>
<style lang="scss">
.extra-space-table > .v-data-table__wrapper > table > tbody > tr > td{
  padding: 1rem 16px;
}
.table-switch {
  margin: 0;
  .v-input__control {
    .v-input__slot {
      margin: 0;
    }
    .v-messages{
      display: none;
    }
  }
}
</style>
