<template>
  <div class="filemanager">
    <v-container>
      <v-row>
        <v-col class="mt-14" cols="12">
              <h2>File Manager</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="10" class="">
          <el-button class="float-left mb-1 theme--light btn btn-radius-corner" type="primary"
                     @click="addDirectory()"
                     size="small" icon="el-icon-folder-add"
                     :disabled="uploadDisabled">
            <v-tooltip v-if="exceedingStorageSpace" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">New Folder</span>
              </template>
              <span>Reached allowed disk space, please remove some files to add new folders</span>
            </v-tooltip>
              <span v-if="!exceedingStorageSpace">New Folder</span>
            </el-button>
          <el-button class="float-left mr-3 mb-1 theme--light btn btn-radius-corner" size="small"
                     @click="openLocalUploadDialog()"
                     type="primary"
                     icon="el-icon-upload"
                     :disabled="uploadDisabled">
            <v-tooltip v-if="exceedingStorageSpace" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">Upload</span>
              </template>
              <span>Reached allowed disk space, please remove some files for new uploads</span>
            </v-tooltip>
            <span v-if="!exceedingStorageSpace">Upload</span>
          </el-button>

          <el-button class="float-left mr-2 mb-1 theme--light btn btn-radius-corner" size="small"
                     @click="refresh()" type="secondary"
                     icon="el-icon-refresh">Refresh</el-button>
          <el-dropdown class="float-left mr-2 mb-1"
                       trigger="click" @command="handleCommand">
            <el-button type="secondary" class="theme--light btn btn-radius-corner" size="small">
              Actions<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="cut" :disabled="multipleSelection.length === 0
               || isReadOnlyOfCurrentDir()
               || this.currentDirInfo.readonly
               || (isReadOnlyBySlug(multipleSelection))
               || exceedingStorageSpace">
                Cut</el-dropdown-item>
              <el-dropdown-item command="copy" :disabled="multipleSelection.length === 0
              || exceedingStorageSpace">
                Copy</el-dropdown-item>
              <el-dropdown-item command="paste" :disabled="operation.parentPath.length === 0
               || isReadOnlyOfCurrentDir()
               || this.currentDirInfo.readonly
               || exceedingStorageSpace">
                Paste</el-dropdown-item>
              <el-dropdown-item command="delete" :disabled="multipleSelection.length === 0
               || isReadOnlyOfCurrentDir()
               || this.currentDirInfo.readonly
               || (isReadOnlyBySlug(multipleSelection))">
                Delete</el-dropdown-item>
              <el-dropdown-item command="rename" :disabled="multipleSelection.length !== 1
               || isReadOnlyOfCurrentDir()
               || this.currentDirInfo.readonly
               || (isReadOnlyBySlug(multipleSelection))
              || exceedingStorageSpace">
                Rename</el-dropdown-item>
              <el-dropdown-item command="size" :disabled="multipleSelection.length === 0">
                Get size</el-dropdown-item>
              <el-dropdown-item command="createZip" :disabled="multipleSelection.length === 0">
                Download ZIP</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button class="float-left mr-2 mb-1 theme--light btn btn-radius-corner
          button-downloads" size="small"
                     @click="routeToDownloads()" type="secondary"
                     ><v-icon small>mdi-cloud-download</v-icon> Downloads</el-button>
        </v-col>
        <v-col cols="2" class="">
          <space-used @exceedingStorageSpace="setExceedingStorageSpace"/>
        </v-col>
      </v-row>

      <!-- BREAD CRUMB BAR start-->
      <el-row class="mPaddingLeft20" style="margin: 25px 0;">
        <el-col :span="24">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>
              <a @click="openDir('/')">Machines</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-for="(dir, index) in stack" v-bind:key="index">
              <a @click="openStack(index)">
                {{ stackWithFileTitle[index] ? stackWithFileTitle[index] : '...' }}
              </a>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <!-- BREAD CRUMB BAR end-->
      <!-- FILES LISTING start-->
      <el-row class="mPaddingLeft5 row-bg justify-center" type="flex">
        <el-col :span="24">
          <!-- FILES LISTING - LIST MODE start-->
          <el-card v-show="displayMode === 'list'">
            <v-pagination
              v-if="totalPages > 1"
              v-model="page"
              :length="totalPages"
              :total-visible="7"
            ></v-pagination>
            <el-table ref="multipleTable" :data="tableDataFinal" v-loading="isTableLoading"
                      style="width: 100%" @selection-change="handleSelectionChange" size="medium"
                      highlight-current-row>
              <el-table-column type="selection" style="padding-left:0px !important" width="30">
              </el-table-column>
              <el-table-column label="Name">
                <template slot-scope="scope">
                  <span v-if="scope.row.isDir"
                        class="fonts notranslate"
                        translate="no"
                        style="font-weight: 500;"
                        @click="openDirFromList(scope.row.name)">
                    <v-icon
                      :small="!specialIcon(getIconNameByFileInfo(scope.row)) && stack.length !== 0"
                      :medium="specialIcon(getIconNameByFileInfo(scope.row))"
                    >
                    {{ getIconNameByFileInfo(scope.row) }}
                    </v-icon>
                    {{ scope.row.title ? scope.row.title : scope.row.name }}</span>
                  <span v-if="!scope.row.isDir" class="fonts" @click="download(scope.row.name)">
                    <v-icon small>{{ getIconNameByFileInfo(scope.row) }}</v-icon>
                    {{ scope.row.title ? scope.row.title : scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="" :align="'right'" width="90">
                <template slot-scope="scope">
                  <div class="element-blocked"
                       v-if="currentDirInfo.readonly
                       || (scope.row.read_only && scope.row.read_only === true)">
                    <v-icon class="mr-1">
                      mdi-alert
                    </v-icon>
                    <span>Training</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Date" :align="'right'" width="320">
                <template slot-scope="scope">
                  <span v-if="!scope.row.isDir" style="margin-right: 10px">
                    {{ format_date(scope.row.datetime, true) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Size" :align="'right'" width="100">
                <template slot-scope="scope">
                  <span v-if="!scope.row.isDir" style="margin-right: 10px">
                    {{ scope.row.size }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column v-if="!isMobile" :align="'right'" width="80">
                <template slot-scope="scope">
                  <span v-if="!scope.row.isDir" style="margin-right: 10px">{{ scope.row.size }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <v-pagination
              v-if="totalPages > 1"
              v-model="page"
              :length="totalPages"
              :total-visible="7"
            ></v-pagination>
          </el-card>
          <!-- FILES LISTING - LIST MODE end-->
        </el-col>
      </el-row>
    </v-container>

  <!-- VIDEO PLAYER DIALOGUE start -->
  <el-dialog v-cloak :visible.sync="displayVideoPlayer">
    <div style="width:100%;margin:0 auto;text-align:center">
      <video width="400" controls ref="videoPlayer">
        <source :src="videoSource" type="video/mp4"> Your browser does not support HTML5 video.
      </video>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeVideoPlayer()">Close</el-button>
    </span>
  </el-dialog>
  <!-- VIDEO PLAYER DIALOGUE end -->
  <file-uploader v-on:refresh="refresh()" :bus="fileUploaderBus" v-bind:current-dir="currentDir"
  :exceedingStorageSpace="exceedingStorageSpace"
  :key="componentKeyFileUploader"
  />
  <!-- AUDIO PLAYER DIALOGUE start-->
  <div v-cloak v-if="displayAudioPlayer && !firstTimeOpened" style="
 position:fixed;
 bottom:0;
 width:100%;
 height:45px;
 padding-top:10px;
 margin:auto;
 text-align:center;
 background: rgba(0, 0, 0, 0.1);
 z-index:1000;">
    <audio controls style="width:700px;" ref="audioPlayer">
      <source :src="audioSource" /> Your browser does not support the audio element.
    </audio>
    <span style="position:absolute;color:black;padding-left:30px;top:18px;font-weight:bold;
    cursor:pointer" title="close" @click="closeAudioPlayer()">X</span>
  </div>
  <!-- AUDIO PLAYER DIALOGUE end-->
    <v-dialog v-model="dialogConfirmCopyMoveRename" max-width="800px">
      <v-card>
        <v-form @submit.prevent="onConfirmCopyMoveRename()" ref="form" class="">
          <v-toolbar dense flat>
            <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            Do you want to {{ commandCopyMoveRename }} with {{ countFiles }} Files?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" class="btn btn-radius-corner">
              Yes</v-btn>
            <v-btn @click="clearDialog" class="btn btn-gray btn-radius-corner">
              Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProgressCopyMoveRename" max-width="400px">
      <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-progress-linear :value="progressPercent"
                               color="primary"
                               height="25"
                               buffer-value="100"
                               stream
                               stripped
            ><template v-slot:default="{ value }">
              <strong>{{ Math.floor(value) }}% </strong>&nbsp;
              <small>( {{countFilesProcessed}} / {{countFiles}} )</small>
            </template>
            </v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="clearDialog" class="btn btn-gray btn-radius-corner">
              Cancel</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from 'vue';
import elementLocale from 'element-ui/lib/locale';
import elementLangEn from 'element-ui/lib/locale/lang/en';
import axios from 'axios';
import saveAs from 'file-saver';
import checkTokenExpiriesDate from '@/helper/checkTokenExpiriesDate';
import FileUploader from '@/components/filemanager/FileUploader.vue';
import dirAdd from '@/api/filemanager/dirAdd';
import deleteFile from '@/api/filemanager/deleteFile';
import renameFile from '@/api/filemanager/renameFile';
import copyFiles from '@/api/filemanager/copyFiles';
import fileList from '@/api/filemanager/fileList';
import getSize from '@/api/filemanager/getSize';
import getCountFiles from '@/api/filemanager/getCountFiles';
import createZip from '@/api/filemanager/createZip';
import SpaceUsed from '@/components/filemanager/SpaceUsed.vue';

export default {
  name: 'FileManagerPage',
  components: {
    SpaceUsed,
    FileUploader,
  },
  data() {
    return {
      itemsPerPage: 300,
      totalPages: 0,
      showPagination: false,
      bearer: null,
      apiUrl: process.env.VUE_APP_ROOT_API ? process.env.VUE_APP_ROOT_API.trim().replace(/\/+$/, '') : '',
      audioSource: '',
      currentDir: '/',
      currentDirInfo: [],
      dirBeforeLogout: '',
      displayAudioPlayer: false,
      displayMode: 'list',
      displayVideoPlayer: false,
      firstTimeOpened: true,
      hiddenFiles: true,
      isTableLoading: true,
      multipleSelection: [],
      operation: {
        type: '',
        parentPath: '',
        selectedFiles: '',
      },
      password: '',
      tableData: [],
      totalCurrentDirs: 0,
      totalCurrentFiles: 0,
      username: '',
      videoSource: '',
      fileUploaderBus: new Vue(),
      audioExtensions: ['mp3'],
      videoExtensions: ['mp4'],
      imageExtensions: ['jpeg', 'jpg', 'JPG', 'JPEG', 'ase', 'art', 'bmp', 'blp', 'cd5', 'cit', 'cpt', 'cr2', 'cut', 'dds', 'dib', 'djvu', 'egt', 'exif', 'gif', 'gpl', 'grf', 'icns', 'ico', 'iff', 'jng', 'jfif', 'jp2', 'jps', 'lbm', 'max', 'miff', 'mng', 'msp', 'nitf', 'ota', 'pbm', 'pc1', 'pc2', 'pc3', 'pcf', 'pcx', 'pdn', 'pgm', 'PI1', 'PI2', 'PI3', 'pict', 'pct', 'pnm', 'pns', 'ppm', 'psb', 'psd', 'pdd', 'psp', 'px', 'pxm', 'pxr', 'qfx', 'raw', 'rle', 'sct', 'sgi', 'rgb', 'int', 'bw', 'tga', 'tiff', 'tif', 'vtf', 'xbm', 'xcf', 'xpm', '3dv', 'amf', 'ai', 'awg', 'cgm', 'cdr', 'cmx', 'dxf', 'e2d', 'egt', 'eps', 'fs', 'gbr', 'odg', 'svg', 'stl', 'vrml', 'x3d', 'sxd', 'v2d', 'vnd', 'wmf', 'emf', 'art', 'xar', 'png', 'webp', 'jxr', 'hdp', 'wdp', 'cur', 'ecw', 'iff', 'lbm', 'liff', 'nrrd', 'pam', 'pcx', 'pgf', 'sgi', 'rgb', 'rgba', 'bw', 'int', 'inta', 'sid', 'ras', 'sun', 'tga'],
      page: 1,
      stackWithFileTitle: [],
      exceedingStorageSpace: true,
      componentKeyFileUploader: 0,
      countFiles: 0,
      countFilesProcessed: 0,
      dialogTitle: null,
      commandCopyMoveRename: null,
      fileOrFolderNameCleanToRename: null,
      dialogConfirmCopyMoveRename: false,
      dialogProgressCopyMoveRename: false,
    };
  },
  watch: {
    audioSource() {
      this.$refs.audioPlayer.load();
    },
    videoSource() {
      this.$refs.videoPlayer.load();
    },
    page: {
      deep: false,
      handler() {
        this.openDir(this.currentDir, this.page);
      },
    },
  },
  computed: {
    stack() {
      let substring = this.currentDir;
      if (this.currentDir === '/') {
        return [];
      }
      substring = substring.substring(1);
      return substring.split('/');
    },
    pageWidth() {
      if (this.displayMode === 'gallery') {
        return 'calc(100% - 120px)';
      }
      return '900px';
    },

    tableDataFinal() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalCurrentFiles = 0;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalCurrentDirs = 0;
      const arrDir = [];
      let temp = null;
      // eslint-disable-next-line no-restricted-syntax
      for (temp of this.tableData) {
        // eslint-disable-next-line block-scoped-var
        if (temp.isDir && (this.hiddenFiles || !temp.name.startsWith('.'))) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.totalCurrentDirs += 1;
          // eslint-disable-next-line block-scoped-var
          arrDir.push(temp);
        }
      }
      const arrFile = [];
      // eslint-disable-next-line no-restricted-syntax
      for (temp of this.tableData) {
        if (!temp.isDir && (this.hiddenFiles || !temp.name.startsWith('.'))) {
          if (this.displayMode === 'list' || (this.displayMode === 'gallery'
            && this.isImage(temp.name))) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalCurrentFiles += 1;
            arrFile.push(temp);
          }
        }
      }
      return arrDir.concat(arrFile);
    },
    uploadDisabled() {
      return (
        this.currentDirInfo.readonly || this.isReadOnlyOfCurrentDir()
        || this.exceedingStorageSpace
      );
    },
    progressPercent() {
      if (parseInt(this.countFiles, 10) === 0) {
        return 0;
      }
      return (
        (this.countFilesProcessed / this.countFiles) * 100
      );
    },
  },
  created() {
    elementLocale.use(elementLangEn);
    if (!checkTokenExpiriesDate()) {
      this.$router.push('/logout');
    }
    const bearer = this.$store.getters['user/token'];
    this.bearer = bearer.replace('bearer ', '');
    this.addShortcutListeners();
    this.extractUrlParam();
    this.refresh();
  },
  methods: {
    routeToDownloads() {
      this.$router.push('/filemanager/downloads');
    },
    setExceedingStorageSpace(isIt) {
      this.exceedingStorageSpace = isIt;
    },
    getIconNameByFileInfo(fileInfo) {
      const pathSegments = this.currentDir.substring(1).split('/').filter((entry) => entry.trim() !== '');
      if (fileInfo.isDir) {
        if (pathSegments.length === 0) {
          return 'mdi-domain';
        }
        if (fileInfo.name === 'ai-training') {
          return 'mdi-folder-star';
        }
        if (fileInfo.name === 'ai-testing') {
          return 'mdi-folder-play';
        }
        if (pathSegments.length === 2
          && (pathSegments[1] === 'ai-training' || pathSegments[1] === 'ai-testing')) {
          return 'mdi-folder';
        }
        return 'mdi-folder-text';
      }
      return 'mdi-file-outline';
    },
    specialIcon(iconName) {
      return iconName === 'mdi-folder-star' || iconName === 'mdi-folder-play';
    },
    isMobile() {
      // eslint-disable-next-line no-restricted-globals
      return screen.width <= 720;
    },
    isReadOnlyOfCurrentDir() {
      let substring = this.currentDir;
      substring = substring.substring(1);
      const segmentCount = substring.split('/').length;
      return segmentCount < 2;
    },
    isReadOnlyBySlug(multipleSelection) {
      let hasReadonlyFile = false;
      for (let i = 0; i < multipleSelection.length; i += 1) {
        const row = this.tableData.find((e) => e.name === multipleSelection[i]);
        if (row && row.read_only && row.read_only === true) {
          hasReadonlyFile = true;
          break;
        }
      }
      return hasReadonlyFile;
    },
    // Add shortcut listeners
    addShortcutListeners() {
      document.addEventListener('keydown', (event) => {
        let keyName = event.key;
        if (keyName === 'Control') {
          // do not alert when only Control key is pressed.
          return;
        }
        if (event.ctrlKey) {
          keyName = keyName.toUpperCase();
          if (keyName === 'X') {
            if (this.multipleSelection.length !== 0) {
              this.handleCommand('cut');
            }
          } else if (keyName === 'C') {
            if (this.multipleSelection.length !== 0) {
              this.handleCommand('copy');
            }
          } else if (keyName === 'V') {
            if (this.operation.parentPath.length !== 0) {
              this.handleCommand('paste');
            }
          }
          // Even though event.key is not 'Control' (e.g., 'a' is pressed),
          // event.ctrlKey may be true if Ctrl key is pressed at the same time.
          // alert(`Combination of ctrlKey + ${keyName}`);
        } else if (keyName === 'Delete') {
          if (this.multipleSelection.length !== 0) {
            this.handleCommand('delete');
          }
        } else if (keyName === 'F2') {
          if (this.multipleSelection.length === 1) {
            this.handleCommand('rename');
          }
        } else if (event.altKey && (keyName === 'N' || keyName === 'n')) {
          this.addDirectory();
        }
      }, false);
    },
    playAudio(name) {
      this.displayAudioPlayer = true;
      this.audioSource = `/visiontools2/apis/music.php?path=${this.currentDir === '/' ? '/'
        : `${encodeURIComponent(this.currentDir)}/`}${name}`;
    },
    playVideo(name) {
      this.displayVideoPlayer = true;
      this.videoSource = `/visiontools2/apis/download.php?path=${this.currentDir === '/' ? '/'
        : `${encodeURIComponent(this.currentDir)}/`}${name}`;
    },
    openLocalUploadDialog() {
      this.fileUploaderBus.$emit('openLocalUploadDialog');
    },
    closeAudioPlayer() {
      this.displayAudioPlayer = false;
      this.audioSource = '';
      this.$refs.audioPlayer.load();
    },
    closeVideoPlayer() {
      this.displayVideoPlayer = false;
      this.videoSource = '';
      this.$refs.videoPlayer.load();
    },
    isImage(name) {
      return this.imageExtensions.indexOf(name.substring(name.lastIndexOf('.') + 1)) !== -1;
    },
    isAudio(name) {
      return this.audioExtensions.indexOf(name.substring(name.lastIndexOf('.') + 1)) !== -1;
    },
    isVideo(name) {
      return this.videoExtensions.indexOf(name.substring(name.lastIndexOf('.') + 1)) !== -1;
    },
    extractUrlParam() {
      // eslint-disable-next-line no-restricted-globals
      const paramsPath = location.search.substring(1);
      if (paramsPath) {
        const parameters = paramsPath.split('&');
        if (parameters.length > 0) {
          const firstParam = paramsPath;
          if (firstParam.indexOf('=') !== -1) {
            let firstParamVal = firstParam.split('=')[1];
            if (firstParamVal) {
              firstParamVal = unescape(firstParamVal);
              if (firstParamVal) {
                this.currentDir = firstParamVal;
              }
            }
          }
        }
      }
    },
    setOpearation(type) {
      this.operation.type = type;
      this.operation.parentPath = this.currentDir;
      this.operation.selectedFiles = this.multipleSelection;
    },
    handleCommand(command) {
      if (command === 'cut') {
        this.setOpearation('cut');
      } else if (command === 'copy') {
        this.setOpearation('copy');
      } else if (command === 'paste') {
        this.pasteConfirm();
      } else if (command === 'rename') {
        this.renameConfirm();
      } else if (command === 'size') {
        this.fetchSize();
      } else if (command === 'delete') {
        this.delete();
      } else if (command === 'createZip') {
        this.createZip();
      }
    },
    fetchSize(dir) {
      let dirToSize = null;
      if (dir == null) {
        dirToSize = this.currentDir;
      }
      getSize(JSON.stringify(this.multipleSelection), dirToSize)
        .then((response) => {
          const msg = `File size: ${response.data[0]}`;
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'success',
            show: true,
          });
        })
        .catch((error) => {
          const msg = this.checkForErrorMassage(error, 'Unable to calculate size');
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'error',
            show: true,
          });
        });
    },
    fetchCountFiles(selectedFiles, parentPath) {
      this.$store.dispatch('dialogloader/show', 'Checking files ...');
      this.countFiles = null;
      getCountFiles(JSON.stringify(selectedFiles), parentPath)
        .then((response) => {
          this.countFiles = parseInt(`${response.data[0]}`, 10);
          this.dialogConfirmCopyMoveRename = true;
        })
        .finally(() => this.$store.dispatch('dialogloader/hide'))
        .catch((error) => {
          const msg = this.checkForErrorMassage(error, 'Unable to calculate size');
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'error',
            show: true,
          });
        });
    },
    pasteConfirm() {
      if (this.operation.type === 'cut') {
        this.commandCopyMoveRename = 'cut';
        this.dialogTitle = 'Cut';
      } else {
        this.commandCopyMoveRename = 'copy';
        this.dialogTitle = 'Copy';
      }
      this.fetchCountFiles(this.operation.selectedFiles, this.operation.parentPath);
    },
    paste() {
      this.dialogProgressCopyMoveRename = true;
      copyFiles(
        this.operation.parentPath,
        JSON.stringify(this.operation.selectedFiles),
        this.currentDir,
        this.operation.type === 'cut',
      )
        .then((response) => {
          if (response.data[1].filesProceeded !== undefined) {
            this.countFilesProcessed += response.data[1].filesProceeded;
          }
          if (
            (
              response.data[1].timeExeeded !== undefined
              && response.data[1].timeExeeded === false
            )
            || response.data[1].filesProceeded === 0
            || this.countFilesProcessed >= this.countFiles
          ) {
            let labelCommand = 'copied';
            if (this.operation.type === 'cut') {
              labelCommand = 'cut';
            }
            const msg = `Files or directories ${labelCommand}`;
            this.$store.dispatch('notificationbar/showNotification', {
              msg,
              color: 'success',
              show: true,
            });
            this.operation.parentPath = '';
            this.clearDialog();
            this.refresh();
          } else if (this.dialogProgressCopyMoveRename) {
            this.paste();
          }
        }).catch((error) => {
          this.operation.parentPath = '';
          const msg = this.checkForErrorMassage(error, `Unable to ${this.operation.type} file(s)`);
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'error',
            show: true,
          });
          if (this.dialogProgressCopyMoveRename) {
            this.paste();
          }
        });
    },
    onConfirmCopyMoveRename() {
      switch (this.commandCopyMoveRename) {
        case 'rename':
          this.renameSelectedFiles();
          break;
        case 'cut':
        case 'copy':
          this.paste();
          break;
        default:
          break;
      }
    },
    clearDialog() {
      this.dialogConfirmCopyMoveRename = false;
      this.dialogProgressCopyMoveRename = false;
      this.countFiles = 0;
      this.countFilesProcessed = 0;
      this.fileOrFolderNameCleanToRename = null;
      this.commandCopyMoveRename = null;
      this.dialogTitle = null;
      this.refresh();
    },
    renameConfirm() {
      this.commandCopyMoveRename = 'rename';
      this.dialogTitle = 'Rename';
      this.$prompt('Please enter new name', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        inputValue: this.multipleSelection[0].replace(/^\/+/, '').replace(/\/+$/, ''),
      }).then((value) => {
        // eliminate leading adn ending slashes
        const fileOrFolderNameClean = value.value.replace(/^\/+/, '').replace(/\/+$/, '');
        if (fileOrFolderNameClean) {
          this.fileOrFolderNameCleanToRename = fileOrFolderNameClean;
          this.fetchCountFiles(this.multipleSelection, this.currentDir);
        }
      }).catch((error) => {
        const msg = this.checkForErrorMassage(error, 'Unable to check renaming file');
        this.$store.dispatch('notificationbar/showNotification', {
          msg,
          color: 'success',
          show: true,
        });
      });
    },
    renameSelectedFiles() {
      this.dialogProgressCopyMoveRename = true;
      this.renameProcess();
    },
    renameProcess() {
      renameFile(
        this.multipleSelection[0].replace(/\/+$/, ''),
        this.fileOrFolderNameCleanToRename,
        this.currentDir,
        this.multipleSelection[0].endsWith('/'),
      )
        .then((response) => {
          if (response.data[1].filesProceeded !== undefined) {
            this.countFilesProcessed += response.data[1].filesProceeded;
          }
          if (
            (response.data[1].timeExeeded !== undefined
              && response.data[1].timeExeeded === false
            )
            || response.data[1].filesProceeded === 0
            || this.countFilesProcessed >= this.countFiles
          ) {
            this.clearDialog();
            this.refresh();
            const msg = 'File or directory renamed';
            this.$store.dispatch('notificationbar/showNotification', {
              msg,
              color: 'success',
              show: true,
            });
          } else if (this.dialogProgressCopyMoveRename) {
            this.renameProcess();
          }
        }).catch((error) => {
          const msg = this.checkForErrorMassage(error, 'Unable to rename file');
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'error',
            show: true,
          });
          if (this.dialogProgressCopyMoveRename) {
            setTimeout(this.renameProcess(), 3000);
          }
        });
    },
    delete() {
      this.$confirm('Are you sure want to permanently delete?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        deleteFile(JSON.stringify(this.multipleSelection), this.currentDir)
          .then(() => {
            const msg = 'File or directory deleted';
            this.$store.dispatch('notificationbar/showNotification', {
              msg,
              color: 'success',
              show: true,
            });
            this.refreshIterateOverParent(this.currentDir);
          }).catch((error) => {
            const msg = this.checkForErrorMassage(error, 'Unable to delete file or directory');
            this.$store.dispatch('notificationbar/showNotification', {
              msg,
              color: 'error',
              show: true,
            });
          });
      });
    },
    createZip(dir) {
      let msg = 'Download will be proceeded';
      this.$store.dispatch('notificationbar/showNotification', {
        msg,
        color: 'info',
        show: true,
      });
      let dirToDownload = null;
      if (dir == null) {
        dirToDownload = this.currentDir;
      }
      createZip(JSON.stringify(this.multipleSelection), dirToDownload)
        .then((response) => {
          if (response.data.state === 'ready') {
            msg = `Download ${response.data.state}`;
            this.downloadZip(response.data.id);
          } else {
            msg = 'You will be informed by e-mail when the download is ready.';
          }
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'success',
            show: true,
          });
        })
        .catch(() => {
          msg = 'Unable to create zip';
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'error',
            show: true,
          });
        });
    },
    checkForErrorMassage(error, defaultMessage = 'Unknown Error') {
      let msg = defaultMessage;
      if (error instanceof TypeError) {
        msg = 'Network Error: Server is not responding.';
      } else if (error.response !== undefined) {
        const errorData = error.response.data;
        if ((typeof errorData === 'string' || errorData instanceof String)) {
          if (errorData.trim().length > 0) {
            msg = errorData;
          }
        } else if (Array.isArray(errorData)) {
          if (errorData.join(',').trim().length > 0) {
            msg = errorData.join(',');
          }
        }
      } else if (error.toString().length > 0) {
        msg = error.toString();
      }
      return msg;
    },
    openStack(index) {
      let path = '';
      for (let i = 0; i <= index; i += 1) {
        path = `${path}/${this.stack[i]}`;
      }
      this.openDir(path);
    },
    addDirectory() {
      this.$prompt('Please enter directory name', {
        confirmButtonText: 'Add',
        cancelButtonText: 'Cancel',
      }).then((value) => {
        if (value) {
          dirAdd(value.value, this.currentDir)
            .then(() => {
              const msg = 'Directory Added';
              this.$store.dispatch('notificationbar/showNotification', {
                msg,
                color: 'success',
                show: true,
              });
              this.refresh();
            }).catch((error) => {
              const msg = this.checkForErrorMassage(error, 'Unable to add directory');
              this.$store.dispatch('notificationbar/showNotification', {
                msg,
                color: 'error',
                show: true,
              });
            });
        }
      }).catch(() => { });
    },
    refresh() {
      this.openDir(this.currentDir);
    },
    refreshIterateOverParent(path) {
      fileList(path)
        .then(() => {
          this.openDir(path);
        })
        .catch((error) => {
          if (error.response.data === 'Folder not found') {
            const pathSegments = path.split('/').filter((entry) => entry.trim() !== '');
            if (pathSegments.length > 0) {
              const newPath = `/${pathSegments.slice(0, -1).join('/')}`;
              this.refreshIterateOverParent(newPath);
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((obj) => {
        if (obj.isDir) {
          return `${obj.name}/`;
        }
        return obj.name;
      });
    },
    openDirFromList(name) {
      if (this.currentDir === '/') {
        this.openDir(`/${name}`);
      } else {
        this.openDir(`${this.currentDir}/${name}`);
      }
    },
    openDir(path, page = 1) {
      const pathSegments = path.substring(1).split('/').filter((entry) => entry.trim() !== '');
      if (pathSegments.length < this.stackWithFileTitle.length) {
        this.stackWithFileTitle.splice(pathSegments.length);
      } else if (pathSegments.length > this.stackWithFileTitle.length) {
        const pathLastSegment = pathSegments.pop();
        for (let i = 0; i < this.tableData.length; i += 1) {
          if (pathLastSegment === this.tableData[i].name) {
            this.stackWithFileTitle.push(
              this.tableData[i].title ? this.tableData[i].title : this.tableData[i].name,
            );
            break;
          }
        }
      }
      const start = this.itemsPerPage * (page - 1);
      this.isTableLoading = true;
      fileList(path, start)
        .then((response) => {
          this.currentDir = path;
          this.currentDirInfo = {
            machine: response.data.machine,
            readonly: response.data.readonly,
          };
          this.tableData = response.data.items;
          this.totalPages = Math.ceil(response.data.total / this.itemsPerPage);
          this.isTableLoading = false;
          this.firstTimeOpened = false;
        })
        .catch((error) => {
          const msg = this.checkForErrorMassage(error, 'Unable to list directory');
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'error',
            show: true,
          });
          this.isTableLoading = false;
        });
    },
    getThumbnailSmall(name) {
      console.log('getThumbnailSmall', name);
    },
    download(filename) {
      const config = {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.bearer}`,
        },
      };
      const url = `${this.apiUrl}/api/v1/filemanager/download?path=${encodeURIComponent((this.currentDir === '/' ? '/' : `${this.currentDir}/`) + filename)}`;
      axios
        .get(url, config)
        .then((response) => {
          saveAs(response.data, filename, false);
        })
        .catch((error) => {
          const msg = this.checkForErrorMassage(error, 'Unable to download file');
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'error',
            show: true,
          });
          this.isTableLoading = false;
        });
    },
    downloadZip(fileId) {
      const iFrameName = 'download-zip';
      if (!this.existsIframe(iFrameName)) {
        this.prepareIframe(iFrameName);
      }
      const url = `${this.apiUrl}/api/v1/filemanager/download-zip/${fileId}`;
      const form = document.createElement('form');
      form.method = 'post';
      form.target = iFrameName;
      form.action = url;
      form.innerHTML = `<input type="hidden" name="bearer" value="${this.bearer}">`;

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
      setTimeout(
        () => {
          const iframes = document.getElementsByName(iFrameName);
          for (let i = 0; i < iframes.length; i += 1) {
            if (iframes[i].getAttribute('name') === iFrameName) {
              const result = iframes[i].contentWindow.document.body.innerHTML;
              if (result.length > 0) {
                this.$store.dispatch('notificationbar/showNotification', {
                  result,
                  color: 'error',
                  show: true,
                });
              }
            }
          }
        },
        1500,
      );
    },
    prepareIframe(name) {
      const ifrm = document.createElement('iframe');
      ifrm.setAttribute('name', name);
      ifrm.style.width = '0px';
      ifrm.style.height = '0px';
      document.body.appendChild(ifrm);
    },
    existsIframe(name) {
      const iframes = document.getElementsByName(name);
      for (let i = 0; i < iframes.length; i += 1) {
        if (iframes[i].getAttribute('name') === name) {
          return true;
        }
      }
      return false;
    },
  },
};

</script>

<style lang="scss">

</style>
