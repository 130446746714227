function newCurrentPricing(id, item) {
  // const { id } = item;
  const data = {
    // eslint-disable-next-line
    valid_from: item.valid_from,
    seats: Number(item.seats),
    storage_space: Number(item.storage_space),
    training_minutes: Number(item.training_minutes),
    basic_fee_per_month: Number(item.basic_fee_per_month),
    cost_per_minute: Number(item.cost_per_minute),
    cost_for_more_user: Number(item.cost_for_more_user),
    cost_for_more_minutes: Number(item.cost_for_more_minutes),
    cost_for_more_storage: Number(item.cost_for_more_storage),
    more_storage: Number(item.more_storage),
    more_minutes: Number(item.more_minutes),
    more_user: Number(item.more_user),
    more_gpus: Number(item.more_gpus),
    cost_for_more_gpus: Number(item.cost_for_more_gpus),
  };

  console.log('new currentpricing', data);
  return window.$http({
    url: `/api/v1/admin/company/${id}/pricing/create`,
    method: 'post',
    data,
  });
}

export default newCurrentPricing;
