<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="my-16" cols="12">
          <h2>Reports</h2>
          <h4>Customer Billing History</h4>
        </v-col>
      </v-row>
    </v-container>
    <div class="bg-white py-5 h-60">
      <v-container>
        <v-row>
          <v-col  cols="12">
            <div class="select-wrapper">
              <v-select
                v-model="selectedCompany"
                :items="companies"
                label="Select a company"
                item-text="name"
                @change="getCompanyTransactions"
                :item-value="company => company"
                outlined
              ></v-select>
            </div>
            <hr />
          </v-col>
          <v-col  cols="12">
            <v-sheet :elevation="1" rounded class="pa-5 h-100">
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                max-height="156"
                type="image"
              ></v-skeleton-loader>
              <div v-else>
                <div class="card-header d-flex justify-space-between">
                  <h3 class="card-title ps-3">{{ selectedCompany ? selectedCompany.name : '' }}</h3>
                  <p class="font-weight-light text-end" v-if="selectedCompany">Created:
                    <span>{{ $options.methods.format_date(selectedCompany.created_at) }}</span>
                  </p>
                </div>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <div>
                        Total transactions:
                        <span class="font-weight-bold">
                          {{transactions ? transactions.length : 0}}
                        </span>
                      </div>
                      <div>
                        Current balance:
                        <span class="font-weight-bold">
                          {{ balance ? balance : 0 }} &euro;
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <div>
                        Total trainings:
                        <v-progress-circular
                          indeterminate
                          size="20"
                          width="2"
                          v-if="loadingTotalTrainings"
                        ></v-progress-circular>
                        <span v-else class="font-weight-bold">{{ totalTrainings }}</span>
                      </div>
                      <div>
                        Total minutes:
                        <v-progress-circular
                          indeterminate
                          size="20"
                          width="2"
                          v-if="loadingTotalUsedMinutes"
                        ></v-progress-circular>
                        <span v-else class="font-weight-bold">{{ totalUsedMinutes }}</span>
                      </div>
                      <div>
                        Used storage:
                        <v-progress-circular
                          indeterminate
                          size="20"
                          width="2"
                          v-if="loadingUsedSpace"
                        ></v-progress-circular>
                        <span v-else class="font-weight-bold">{{ usedSpace }} GB</span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <div>
                        <p v-html="formattedAddress"></p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div>
              <v-data-table
                v-if="transactions"
                :headers="headers"
                :loading="loading"
                :items="transactions"
                :items-per-page="25"
                :footer-props="{'items-per-page-options':[ 25, 50, 100, -1]}"
                disable-sort
                class="elevation-1 reports-history-table"
              >
                <template v-slot:top>
                  <div class="d-flex justify-space-between">
                    <h3 class="card-title-table">History</h3>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  {{format_date(item.created_at)}}
                </template>
                <template v-slot:item.transaction_type="{ item }">
                  {{item.amount > 0 ? 'Deposit' : 'Charge'}}
                </template>
                item.amount > 0
                <template v-slot:item.description="{ item }">
                  {{item.description}} <br>
                  <router-link
                    :to="{ path: '/reports/billing-details',
                  query: { date: item.month_year, company: selectedCompany.id }}"
                    class="black-underline-link"
                    v-if="item.transaction_type === 'training_monthly_summary'">
                    Minutes used previous month
                  </router-link>
                </template>
                <template v-slot:item.amount="{ item }">
                  <div class="box-price">
                  <span :class="{'green-price': item.amount > 0, 'red-price': item.amount < 0}">
                    {{item.amount}} &euro;
                  </span>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>

import getAllCompanies from '@/api/admin/company/getAllCompanies';
import getTransactions from '@/api/admin/statistics/getTransactions';
import getTrainingStatistics from '@/api/admin/statistics/getTrainingTrainingStatistics';
import getCompanyUsedStorageSpace from '@/api/admin/statistics/getCompanyUsedStorageSpace';

export default {
  name: 'BillingHistory',
  data() {
    return {
      history: null,
      loading: false,
      userRoles: null,
      headers: [
        {
          text: 'Date',
          align: 'start',
          value: 'created_at',
        },
        { text: 'Action', value: 'transaction_type' },
        { text: 'Description', value: 'description' },
        { text: 'Total', value: 'amount', align: 'end' },
      ],
      transactions: null,
      monthMenu: false,
      companies: [],
      selectedCompany: null,
      balance: null,
      totalTrainings: null,
      totalUsedMinutes: null,
      loadingUsedSpace: true,
      loadingTotalUsedMinutes: true,
      loadingTotalTrainings: true,
      usedSpace: 0,
    };
  },
  created() {
    this.fetchCompanies();
  },
  computed: {
    formattedAddress() {
      if (!this.selectedCompany) {
        return '';
      }
      const {
        street,
        zip,
        city,
        country,
      } = this.selectedCompany;
      const formattedCountry = country || '';
      // eslint-disable-next-line
      return `${street || ''}<br>${zip ? zip + ' ' + city : ''}<br>${formattedCountry}`;
    },
  },
  methods: {
    fetchCompanies() {
      getAllCompanies()
        .then((response) => {
          this.companies = response.data.data;
          // eslint-disable-next-line prefer-destructuring
          this.selectedCompany = this.companies[0];
          this.getCompanyTransactions(this.selectedCompany);
          this.fetchTotalMinutesAndTrainingsAndTrainings(this.selectedCompany);
          this.fetchTotalUsedSpace(this.selectedCompany);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getCompanyTransactions(company) {
      this.loading = true;
      this.loadingUsedSpace = true;
      this.loadingTotalTrainings = true;
      this.loadingTotalUsedMinutes = true;
      const res = await getTransactions(company.id);
      this.transactions = res.data;
      try {
        // eslint-disable-next-line
        this.transactions.reduce((acc, item) => {
          // eslint-disable-next-line
          const date = item.created_at;
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(item);
          return acc;
        }, {});
        this.calculateBalance(this.transactions);
        await this.fetchTotalUsedSpace(company);
        await this.fetchTotalMinutesAndTrainingsAndTrainings(company);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    calculateBalance(transactions) {
      let balance = 0;
      transactions.forEach((transaction) => {
        balance += parseFloat(transaction.amount);
      });
      this.balance = balance.toFixed(2);
    },
    async fetchTotalMinutesAndTrainingsAndTrainings(company) {
      getTrainingStatistics(company.id)
        .then((response) => {
          this.totalTrainings = response.data.number;
          this.totalUsedMinutes = response.data.minutes;
        })
        .finally(() => {
          this.loadingTotalUsedMinutes = false;
          this.loadingTotalTrainings = false;
        });
    },
    async fetchTotalUsedSpace(company) {
      getCompanyUsedStorageSpace(company.id)
        .then((response) => {
          this.usedSpace = Math.round((response.data.size * 10) / (1024 * 1024 * 1024)) / 10;
        })
        .finally(() => {
          this.loadingUsedSpace = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-white {
  background: white;
}
.h-60 {
  min-height: 60vh;
}
.select-wrapper {
  max-width: 200px;
}
hr {
  border-style: solid;
}
.card-title-table {
  font-size: 24px;
  font-weight: 500;
  padding: 1rem 1rem 1.5rem;
}
.reports-history-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0.5rem 16px;
  text-transform: capitalize;
}
.box-price {
  width: 100%;
  display: flex;
  justify-content: right;
  span{
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
    background: rgb(0 0 0 / 10%);
  }
  .green-price {
    background: rgb(33 186 69 / 10%);
  }
  .red-price {
    background: rgb(219 40 40 / 10%);
  }
}
.black-underline-link {
  color: inherit!important;
  text-decoration: underline;
}
</style>
