function renameFile(oldName, newName, parentPath, isDir) {
  const data = {
    oldName,
    newName,
    parentPath,
    isDir,
  };
  return window.$http({
    url: '/api/v1/filemanager/rename',
    method: 'post',
    data,
  });
}

export default renameFile;
