function deleteCompany(id) {
  return window.$http({
    url: '/api/v1/company/delete',
    method: 'delete',
    data: {
      id,
    },
  });
}

export default deleteCompany;
