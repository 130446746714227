<template>
  <v-row >
      <v-col class="mt-5" cols="12">
        <h3>Billing</h3>
      </v-col>
    <v-col
      cols="12"
      md="12"
      sm="12">

      <v-data-table
        v-if="transactions"
        :headers="headers"
        :loading="loading"
        :items="transactions"
        :items-per-page="25"
        :footer-props="{'items-per-page-options':[ 25, 50, 100, -1]}"
        disable-sort
        class="elevation-1 history-table"
      >
        <template v-slot:top>
          <div class="d-flex justify-space-between items-center">
            <h3 class="card-title-table">History</h3>
            <div class="pt-3 pr-4 pl-4">
              <v-btn
                v-if="userRoles && userRoles[0] !== 'ROLE_USER'"
                @click="createBalanceSheet()"
              >
                <v-icon left>mdi-file-document</v-icon>
                Create balance sheet
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{format_date(item.created_at)}}
        </template>
        <template v-slot:item.transaction_type="{ item }">
          {{item.amount > 0 ? 'Deposit' : 'Charge'}}
        </template>
        item.amount > 0
        <template v-slot:item.description="{ item }">
          {{item.description}} <br>
          <router-link :to="{ path: '/settings/billing/details',
            query: { month_year: item.month_year }}" class="black-underline-link"
            v-if="item.transaction_type === 'training_monthly_summary'">
            Minutes used previous month</router-link>
        </template>
        <template v-slot:item.amount="{ item }">
          <div class="box-price">
             <span :class="{'green-price': item.amount > 0, 'red-price': item.amount < 0}">
                {{item.amount}} &euro;
             </span>
          </div>
        </template>
        <template v-slot:item.print="{ item }">
          <div style="width: 100%; display: flex; justify-content: right;">
            <a v-if="item.amount != 0" title="print" @click="openReportDialog(item)">
              <v-icon center>mdi-printer</v-icon>
            </a>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="createReportDialog" max-width="800px">
        <v-card>
          <v-form @submit.prevent="generatePDF" ref="form">
            <v-toolbar dense flat>
              <v-toolbar-title>Create report sheet</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-textarea
                  class="remarks-textarea"
                  rows="3"
                  :counter="200"
                  label="Remarks"
                  v-model="remarks"
                  :rules="remarksRules"
                >
              </v-textarea>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="btn btn-radius-corner" @click="generatePDF">Create</v-btn>
                <v-btn @click="closeReportDialog" class="btn btn-gray btn-radius-corner">
                  Cancel</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import getTransactions from '@/api/user/getTransactions';
import createReportSheet from '@/api/customer/createReportSheet';

export default {
  name: 'History',
  data() {
    return {
      history: null,
      loading: false,
      userRoles: null,
      headers: [
        {
          text: 'Date',
          align: 'start',
          value: 'created_at',
        },
        { text: 'Action', value: 'transaction_type' },
        { text: 'Description', value: 'description' },
        { text: 'Total', value: 'amount' },
        { text: '', value: 'print' },
      ],
      transactions: null,
      monthMenu: false,
      transactionItem: null,
      createReportDialog: false,
      remarks: '',
      remarksRules: [(v) => v?.length <= 200 || 'Max 200 characters'],
    };
  },
  created() {
    this.getTodayDate();

    this.userRoles = this.$store.getters['user/roles'];
  },
  methods: {
    getTodayDate() {
      this.fetchTransactions();
    },
    async fetchTransactions() {
      const res = await getTransactions();
      this.transactions = res.data;
      // group items based on created date.
      const groupedItems = this.transactions.reduce((acc, item) => {
        // eslint-disable-next-line
        const date = item.created_at;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      }, {});
      console.log('groupedItems', groupedItems);
      this.loading = false;
    },
    createBalanceSheet() {
      this.$router.push('/customer/report-sheet');
    },
    generatePDF() {
      if (!this.$refs.form.validate()) return;
      const transactionDate = this.transactionItem.created_at.split('T')[0];
      const transactionId = this.transactionItem.id;
      createReportSheet(transactionDate, transactionDate, this.remarks, transactionId)
        .then(() => {
          this.closeReportDialog();
        })
        .finally(() => {
          this.transactionItem = null;
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    openReportDialog(item) {
      this.createReportDialog = true;
      this.transactionItem = item;
    },
    closeReportDialog() {
      this.createReportDialog = false;
      this.$nextTick(() => {
        this.remarks = '';
        this.transactionItem = null;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>
<style lang="scss">
.card-title-table {
  font-size: 24px;
  font-weight: 500;
  padding: 1rem 1rem 1.5rem;
}
 .history-table > .v-data-table__wrapper > table > tbody > tr > td{
   padding: 0.5rem 16px;
   text-transform: capitalize;
 }
.history-table > .v-data-table__wrapper > table > thead > tr > th {
  &:last-child {
    text-align: right!important;
  }
}
  .box-price {
   width: 100%;
   display: flex;
   justify-content: left;
   span{
     padding: 0.3rem 0.5rem;
     border-radius: 4px;
     background: rgb(0 0 0 / 10%);
   }
   .green-price {
     background: rgb(33 186 69 / 10%);
   }
   .red-price {
     background: rgb(219 40 40 / 10%);
   }
 }
.black-underline-link {
  color: inherit!important;
  text-decoration: underline;
}
</style>
