<template>
  <div>
    <v-row class="justify-start mt-8">
      <v-col cols="12">
        <h3>{{ $t("company.company_form.plan") }}</h3>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="12">
                <h4 class="subtitle-1">
                  {{ $t("company.company_form.basic_package") }}
                </h4>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Seats (Users)"
                  v-model="editedItem.pricing.seats"
                  value=""
                  :rules="[rules.range(1, 99)]"
                  :disabled="isEditing"
                ></v-text-field>
                <form-error :errors="formErrors.max_user_number" />
                <v-text-field
                  label="Storage (GB)"
                  v-model="editedItem.pricing.storage_space"
                  value=""
                  :rules="[rules.range(1, 99999)]"
                  :disabled="isEditing"
                ></v-text-field>
                <form-error :errors="formErrors.max_bucket_space" />
                <v-text-field
                  label="Training (minutes)"
                  v-model="editedItem.pricing.training_minutes"
                  :rules="[rules.range(1, 99999)]"
                  value=""
                  :disabled="isEditing"
                ></v-text-field>
                <form-error :errors="formErrors.max_training_minutes" />
                <v-text-field
                  label="Basic fee per month (Euro)"
                  v-model="editedItem.pricing.basic_fee_per_month"
                  :rules="[rules.range(1, 99999)]"
                  value=""
                  :disabled="isEditing"
                ></v-text-field>
                <form-error :errors="formErrors.basic_fee_month" />
                <v-text-field
                  label="Cost per minute (Euro)"
                  v-model="editedItem.pricing.cost_per_minute"
                  :rules="[rules.range(0.01, 99999)]"
                  value=""
                  :disabled="isEditing"
                ></v-text-field>
                <form-error :errors="formErrors.cost_per_min" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="12">
                <h4 class="subtitle-1">
                  {{ $t("company.company_form.extension_costs") }}
                </h4>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="More Users"
                  v-model="editedItem.pricing.more_user"
                  value=""
                  :disabled="disableOldPricing"
                  :rules="[rules.range(0, 99999)]"
                ></v-text-field>
                <form-error :errors="formErrors.more_user" />
                <v-text-field
                  label="More Storage"
                  v-model="editedItem.pricing.more_storage"
                  value=""
                  :disabled="disableOldPricing"
                  :rules="[rules.range(0, 99999)]"
                ></v-text-field>
                <form-error :errors="formErrors.more_storage" />
                <v-text-field
                  label="More minutes"
                  v-model="editedItem.pricing.more_minutes"
                  :rules="[rules.range(0, 99999)]"
                  :disabled="disableOldPricing"
                  value=""
                ></v-text-field>
                <form-error :errors="formErrors.more_minutes" />
                <v-text-field
                  label="Additional GPU"
                  v-model="editedItem.pricing.more_gpus"
                  :disabled="disableOldPricing"
                  :rules="[rules.range(0, 99999)]"
                  value=""
                ></v-text-field>
                <form-error :errors="formErrors.more_gpus" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Costs +1 User"
                  v-model="editedItem.pricing.cost_for_more_user"
                  value=""
                  :rules="[rules.range(0, 99999)]"
                  :disabled="isEditing"
                ></v-text-field>
                <v-text-field
                  label="Cost +100 GB"
                  v-model="editedItem.pricing.cost_for_more_storage"
                  value=""
                  :rules="[rules.range(0, 99999)]"
                  :disabled="isEditing"
                ></v-text-field>
                <v-text-field
                  label="Cost +1000 minutes"
                  v-model="editedItem.pricing.cost_for_more_minutes"
                  :rules="[rules.range(0, 99999)]"
                  value=""
                  :disabled="isEditing"
                ></v-text-field>
                <v-text-field
                  label="Cost +1 GPU"
                  v-model="editedItem.pricing.cost_for_more_gpus"
                  :rules="[rules.range(0, 99999)]"
                  value=""
                  :disabled="isEditing"
                ></v-text-field>
                <form-error :errors="formErrors.cost_for_more_gpus" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <!-- future pricing -->
      <v-col cols="12" sm="12" v-if="editedItem.futurePricing">
        <v-col cols="12">
        </v-col>
        <v-row>
            <v-col cols="12" sm="12">
                <div class="d-flex justify-space-between">
                  <p class="mb-0">Future pricing from: {{ editedItem.futurePricing.valid_from }}</p>
                  <a type="button" v-if="editedItem.futurePricing"
                     @click="deleteFuturePlan(editedItem.futurePricing.id)" class="ml-3">
                    Delete Future Plan</a>
                </div>
            </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="12">
                <h4 class="subtitle-1">
                  {{ $t("company.company_form.basic_package") }}
                </h4>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="Seats (Users)"
                  v-model="editedItem.futurePricing.seats"
                  value=""
                  :rules="[rules.range(1, 99)]"
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <form-error :errors="formErrors.max_user_number" />
                <v-text-field
                  label="Storage (GB)"
                  v-model="editedItem.futurePricing.storage_space"
                  value=""
                  :rules="[rules.range(1, 99999)]"
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <form-error :errors="formErrors.max_bucket_space" />
                <v-text-field
                  label="Training (minutes)"
                  v-model="editedItem.futurePricing.training_minutes"
                  :rules="[rules.range(1, 99999)]"
                  value=""
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <form-error :errors="formErrors.max_training_minutes" />
                <v-text-field
                  label="Basic fee per month (Euro)"
                  v-model="editedItem.futurePricing.basic_fee_per_month"
                  :rules="[rules.range(1, 99999)]"
                  value=""
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <form-error :errors="formErrors.basic_fee_month" />
                <v-text-field
                  label="Cost per minute (Euro)"
                  v-model="editedItem.futurePricing.cost_per_minute"
                  :rules="[rules.range(0.01, 99999)]"
                  value=""
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <form-error :errors="formErrors.cost_per_min" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="12">
                <h4 class="subtitle-1">
                  {{ $t("company.company_form.extension_costs") }}
                </h4>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="More Users"
                  v-model="editedItem.futurePricing.more_user"
                  value=""
                  :disabled="disableFuturePricing"
                  :rules="[rules.range(0, 99999)]"
                ></v-text-field>
                <form-error :errors="formErrors.more_user" />
                <v-text-field
                  label="More Storage"
                  v-model="editedItem.futurePricing.more_storage"
                  value=""
                  :disabled="disableFuturePricing"
                  :rules="[rules.range(0, 99999)]"
                ></v-text-field>
                <form-error :errors="formErrors.more_storage" />
                <v-text-field
                  label="More minutes"
                  v-model="editedItem.futurePricing.more_minutes"
                  :rules="[rules.range(0, 99999)]"
                  value=""
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <form-error :errors="formErrors.more_minutes" />
                <v-text-field
                  label="Additional GPU"
                  v-model="editedItem.futurePricing.more_gpus"
                  :rules="[rules.range(0, 99999)]"
                  value=""
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <form-error :errors="formErrors.more_gpus" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Costs +1 User"
                  v-model="editedItem.futurePricing.cost_for_more_user"
                  value=""
                  :rules="[rules.range(0, 99999)]"
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <v-text-field
                  label="Cost +100 GB"
                  v-model="editedItem.futurePricing.cost_for_more_storage"
                  value=""
                  :rules="[rules.range(0, 99999)]"
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <v-text-field
                  label="Cost +1000 minutes"
                  v-model="editedItem.futurePricing.cost_for_more_minutes"
                  :rules="[rules.range(0, 99999)]"
                  value=""
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <v-text-field
                  label="Cost +1 GPU"
                  v-model="editedItem.futurePricing.cost_for_more_gpus"
                  :rules="[rules.range(0, 99999)]"
                  value=""
                  :disabled="disableFuturePricing"
                ></v-text-field>
                <form-error :errors="formErrors.cost_for_more_gpus" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import FormError from '@/components/_common/FormError.vue';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';

export default {
  name: 'PlanList',
  components: {
    FormError,
  },
  mixins: [FormValidationRulesMixin],
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
    },
    disableOldPrice: {
      type: Boolean,
    },
    formErrors: {
      type: Object,
    },
  },
  computed: {
    disableOldPricing() {
      return !this.disableOldPrice;
    },
    disableFuturePricing() {
      const validFrom = this.editedItem.futurePricing.valid_from;
      const targetDate = new Date(validFrom);
      const today = new Date();

      if (today <= targetDate) return false;
      return true;
    },
  },
  data() {
    return {};
  },
  methods: {
    deleteFuturePlan(id) {
      this.$emit('delete', id);
    },
  },
};
</script>
