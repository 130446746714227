function copyFiles(oldParentPath, files, newParentPath, move = false) {
  const data = {
    oldParentPath,
    files,
    newParentPath,
    move,
  };
  return window.$http({
    url: '/api/v1/filemanager/copy-or-move',
    method: 'post',
    data,
  });
}

export default copyFiles;
