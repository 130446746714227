<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="my-16" cols="12">
          <h2>Reports</h2>
          <h4>Review customer billing details</h4>
        </v-col>
      </v-row>
    </v-container>
    <div class="bg-white py-5 h-60">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="select-wrapper">
              <v-select
                v-model="selectedCompany"
                :items="companies"
                label="Select a company"
                item-text="name"
                item-value="id"
                @change="fetchTrainingsForCompany(filterMonth)"
                outlined
                :disabled="loading"
              ></v-select>
            </div>
            <hr />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="d-flex justify-space-between">
              <div class="d-flex">
                <div class="mr-3">
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="monthMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterMonth"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterMonth"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset()
                          * 60000)).toISOString().substr(0, 10)"
                        min="2023-01-01"
                        @change="save"
                        :show-current="false"
                        type="month"
                        elevation="1"
                        :disabled="loading"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div>
                  <v-select
                    v-model="selectedStatus"
                    :items="allStatus"
                    itemText="name"
                    placeholder="Select Status"
                    itemValue="value"
                    @change="filterRows"
                    :disabled="loading"
                  ></v-select>
                </div>
              </div>
              <div>
                <v-btn
                  small
                  text
                  @click="resetFilter()"
                >clear filter</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12">
            <template>
              <v-data-table
                :headers="headers"
                :items="monthly_cost"
                class="elevation-1 billing-details-table"
                :loading="loading"
                :items-per-page="25"
                :footer-props="{'items-per-page-options':[ 25, 50, 100, -1]}"
                disable-sort
              >
                <template v-slot:top>
                  <div class="d-flex justify-space-between">
                    <h3 class="card-title-table">Billing Details</h3>
                  </div>
                </template>
                <template v-slot:item.started_at="{ item }">
                  {{ format_date(item.started_at) }}
                </template>
                <template v-slot:item.finished_at="{ item }">
                  {{ format_date(item.finished_at, true) }}
                </template>
                <template v-slot:item.duration="{ item }">
                  {{ toHoursAndMinutes(item.duration) }}
                </template>
                <template v-slot:item.cost="{ item }">
                  {{item.cost}} &euro;
                </template>
                <template
                  v-slot:footer
                >
                  <div class="billings-detail-footer">
                    <p>{{ monthly_cost.length }} Trainings</p>
                    <div class="d-flex">
                      <p class="mr-5">{{ toHoursAndMinutes(allDuration) }}</p>
                      <p>{{ total_cost.toFixed(2) }} &euro;</p>
                    </div>
                  </div>
                </template>
                <template v-slot:no-data v-if="companyError">
                  <p>No trainings found for given company and date range</p>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>

import getTrainingsForCompany from '@/api/admin/statistics/getTrainingsForCompany';
import getAllCompanies from '@/api/admin/company/getAllCompanies';

export default {
  name: 'BillingHistory',
  components: {
  },
  data() {
    return {
      loading: true,
      search: '',
      headers: [
        {
          text: 'Created',
          align: 'start',
          value: 'started_at',
        },
        { text: 'Creator', value: 'creator' },
        { text: 'Title', value: 'title' },
        {
          text: 'Status',
          value: 'state',
        },
        { text: 'Finishing Date', value: 'finished_at' },
        { text: 'Duration', value: 'duration' },
        { text: 'Cost', align: 'end', value: 'cost' },
      ],
      monthly_cost: [
        {
          started_at: '',
          creator: '',
          title: '',
          state: '',
          finished_at: '',
          duration: '',
          cost: '',
          id: '',
        },
      ],
      filterMonth: new Date(),
      monthMenu: false,
      allDuration: 0,
      total_cost: 0,
      selectedStatus: 'all',
      allStatus: [
        { name: 'Aborted', value: 'aborted' },
        { name: 'Finished', value: 'finished' },
      ],
      selectedCompany: null,
      companies: [],
      filteredTrainings: [],
      companyError: false,
    };
  },
  async created() {
    this.companies = await this.fetchCompanies();
    this.setSelectedCompanyFromRoute();
    this.setFilterDateFromRoute();
  },
  methods: {
    resetFilter() {
      this.selectedStatus = 'all';
      this.getTodayDate();
      this.filteredTrainings = [...this.monthly_cost];
    },
    toHoursAndMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      if (hours > 0) {
        return `${hours} h ${minutes} min`;
      }
      return `${minutes} min`;
    },
    save(date) {
      this.$refs.menu.save(date);
      this.fetchTrainingsForCompany(date);
    },
    getTodayDate(paramDate = null) {
      this.filterMonth = new Date();
      if (paramDate) {
        this.filterMonth = new Date(paramDate);
      }
      const year = this.filterMonth.getFullYear();
      const month = this.filterMonth.getMonth() + 1;
      // eslint-disable-next-line
      this.filterMonth = year + '-' + month;
      this.fetchTrainingsForCompany(this.filterMonth);
    },
    filterRows() {
      const filtered = this.filteredTrainings.filter((item) => {
        if (this.selectedStatus === 'all') {
          return true;
        }
        return item.state === this.selectedStatus;
      });
      this.monthly_cost = filtered;
      this.allDuration = 0;
      this.total_cost = 0;
      filtered.forEach((item) => {
        this.allDuration += item.duration;
        this.total_cost += item.cost;
      });
    },
    async fetchCompanies() {
      const response = await getAllCompanies();
      return response.data.data;
    },
    companyExists(companyId) {
      return this.companies.some((company) => company.id === parseInt(companyId, 10));
    },
    setSelectedCompanyFromRoute() {
      if (this.$route.query.company && this.companyExists(this.$route.query.company)) {
        this.selectedCompany = parseInt(this.$route.query.company, 10);
      } else {
        this.selectedCompany = this.companies[0].id;
      }
    },
    setFilterDateFromRoute() {
      if (this.$route.query.date) {
        this.getTodayDate(this.$route.query.date);
      } else {
        this.getTodayDate();
      }
    },

    async fetchTrainingsForCompany(fulldate) {
      this.loading = true;
      this.filteredTrainings = [];
      this.selectedStatus = 'all';
      this.allDuration = 0;
      this.total_cost = 0;
      const date = fulldate.split('-');
      try {
        const res = await getTrainingsForCompany(this.selectedCompany, date[1], date[0]);
        this.monthly_cost = res.data;
        this.filteredTrainings = [...this.monthly_cost];
        if (res.data && res.data.length) {
          // eslint-disable-next-line
          for (const key in res.data) {
            this.allDuration += parseInt(res.data[key].duration, 0);
            this.total_cost += parseFloat(res.data[key].cost);
          }
        }
        this.loading = false;
      } catch (err) {
        this.companyError = true;
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-white {
  background: white;
}
.h-60 {
  min-height: 60vh;
}
.select-wrapper {
  max-width: 200px;
}
hr {
  border-style: solid;
}
.card-title-table {
  font-size: 24px;
  font-weight: 500;
  padding: 1rem 1rem 1.5rem;
}
.billing-details-table > .v-data-table__wrapper > table > tbody > tr > td{
  padding: 0.5rem 16px;
  text-transform: capitalize;
}
.billing-details-table > .v-data-table__wrapper > table > thead > tr > th {
  &:last-child {
    text-align: right!important;
  }
}
.billings-detail-footer {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  p {
    font-weight: bold
  }
}
.box-price {
  width: 100%;
  display: flex;
  justify-content: right;
  span{
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
    background: rgb(0 0 0 / 10%);
  }
}
</style>
