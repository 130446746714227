<template>
  <v-row >
    <v-col
      cols="12"
      md="12"
      sm="12">
        <v-sheet :elevation="1" rounded class="pa-5">
          <h3 class="card-title">{{ $t('settings.pricing.title') }}</h3>
         <v-row>
           <v-col
             cols="12"
             md="5"
             sm="12">
             <div class="pricing-list">
               <ul>
                 <li>
                   <v-row class="flex-row-reverse flex-sm-row">
                     <v-col cols="6">
                       <h2 class="unit-title">
                         {{ pricingCalc ? pricingCalc.package_fee : '--' }} &euro;
                       </h2>
                     </v-col>
                     <v-col cols="6">
                       <p class="pricing-info">Package fee / month</p>
                     </v-col>
                   </v-row>
                 </li>
                 <li>
                   <v-row class="flex-row-reverse flex-sm-row">
                     <v-col cols="6">
                       <h2 class="unit-title">
                         {{ pricingCalc ? pricingCalc.extension_fee : '--' }} &euro;
                       </h2>
                     </v-col>
                     <v-col cols="6">
                       <p class="pricing-info">Extension fee / month</p>
                     </v-col>
                   </v-row>
                 </li>
               </ul>
               <hr />
               <v-row class="flex-row-reverse flex-sm-row">
                 <v-col cols="6">
                   <h2 class="unit-title text-right">
                     {{ pricingCalc ? pricingCalc.total : '--' }} &euro;
                   </h2>
                 </v-col>
                 <v-col cols="6">
                   <p class="pricing-info">Total / month</p>
                 </v-col>
               </v-row>
             </div>
           </v-col>
           <v-col
             cols="12"
             md="4"
             sm="12">
             <div class="pricing-used-res">
               <p>available {{ this.currentCompany.pricing.seats
               + this.currentCompany.pricing.more_user }} seats, {{ volumeSize }} GB,
               {{ this.currentCompany.pricing.training_minutes
                     + this.currentCompany.pricing.more_minutes }} minutes,
                 {{ this.totalGpus }} GPU{{ this.totalGpus > 1 ? `&nbsp;´s` : "" }}
               </p>
             </div>
           </v-col>
           <v-col
             cols="12"
             md="3"
             sm="12">
             <div class="d-flex justify-end">
               <v-btn class="btn btn-radius-corner btn-balance"
                      x-large>Balance {{ balance }} &euro;</v-btn>
             </div>
           </v-col>
         </v-row>
        </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import getPricing from '@/api/customer/getPricing';
import getAccountBalance from '@/api/customer/getAccountBalance';

export default {
  name: 'Pricing',
  props: {
    usedSpace: {
      default: null,
      type: Number,
    },
  },
  data() {
    return {
      volumeSize: null,
      pricingData: null,
      pricingCalc: null,
      usedMinutes: null,
      usedItem: null,
      balance: null,
      currentCompany: null,
      totalGpus: 1,
    };
  },
  created() {
    this.currentCompany = this.$store.getters['user/company'];
    this.fetchPricingData();
    this.getBalance();

    this.volumeSize = this.currentCompany.pricing.storage_space
      + this.currentCompany.pricing.more_storage;
    this.totalGpus += this.currentCompany.pricing.more_gpus;
  },
  methods: {
    async getBalance() {
      const balanceRes = await getAccountBalance();
      this.balance = balanceRes.data.account_balance.toFixed(2);
    },
    async fetchPricingData() {
      await getPricing()
        .then((response) => {
          this.pricingData = response.data;
          this.calculatePricing(response.data);
        });
    },
    calculatePricing(res) {
      const costOfExtraUser = parseFloat(res.more_user) * parseFloat(res.cost_for_more_user);
      const costOfMinutes = (parseFloat(res.more_minutes) / 1000)
        * parseFloat(res.cost_for_more_minutes);
      const costOfGpu = parseFloat(res.more_gpus)
        * parseFloat(res.cost_for_more_gpus);
      const costOfStorage = (parseFloat(res.more_storage) / 100)
        * parseFloat(res.cost_for_more_storage);
      const extensionFee = parseFloat(costOfExtraUser + costOfMinutes + costOfStorage + costOfGpu);
      const total = parseFloat(res.basic_fee_per_month) + extensionFee;
      this.pricingCalc = {
        package_fee: parseFloat(res.basic_fee_per_month).toFixed(2),
        extension_fee: extensionFee.toFixed(2),
        total: total.toFixed(2),
      };
      this.usedMinutes = this.pricingData
        ? this.pricingData.training_minutes + this.pricingData.more_minutes : 0;
    },
  },
};
</script>
<style>
.card-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 2rem;
}

.btn-balance {
  pointer-events: none;
}
</style>
