function sendEmail(email, emailPermissions) {
  console.log('sendEmail', email);
  const temp = {
    can_send_to_customers: emailPermissions.includes('can_send_to_customers'),
    can_send_to_users: emailPermissions.includes('can_send_to_users'),
  };
  const data = {
    subject: email.subject,
    message: email.message,
    emailPermissions: temp,
  };

  return window.$http({
    url: '/api/v1/admin/send-email',
    method: 'post',
    data,
  });
}

export default sendEmail;
