<template>
  <v-row>
    <v-col cols="12" sm="12" class="remove-top-space">
      <v-row>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu"
            v-model="monthMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="items.valid_from"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="items.valid_from"
              :min="minDate"
              @change="save"
              :show-current="false"
              type="month"
              elevation="1"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6">
      <v-row>
        <v-col cols="12" sm="12">
          <h4 class="subtitle-1">{{ $t('company.company_form.basic_package') }}</h4>
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field
            label="Seats (Users)"
            v-model="items.seats"
            value=""
            :rules="[rules.range(1,99)]"
          ></v-text-field>
          <form-error :errors="formErrors.max_user_number" />
          <v-text-field
            label="Storage (GB)"
            v-model="items.storage_space"
            value=""
            :rules="[rules.range(1,99999)]"
          ></v-text-field>
          <form-error :errors="formErrors.max_bucket_space" />
          <v-text-field
            label="Training (minutes)"
            v-model="items.training_minutes"
            :rules="[rules.range(1,99999)]"
            value=""
          ></v-text-field>
          <form-error :errors="formErrors.max_training_minutes" />
          <v-text-field
            label="Basic fee per month (Euro)"
            v-model="items.basic_fee_per_month"
            :rules="[rules.range(1,99999)]"
            value=""
          ></v-text-field>
          <form-error :errors="formErrors.basic_fee_month" />
          <v-text-field
            label="Cost per minute (Euro)"
            v-model="items.cost_per_minute"
            :rules="[rules.range(0.01,99999)]"
            value=""
          ></v-text-field>
          <form-error :errors="formErrors.cost_per_min" />
        </v-col>
      </v-row>

    </v-col>
    <v-col cols="12" sm="6">
      <v-row>
        <v-col cols="12" sm="12">
          <h4 class="subtitle-1">{{ $t('company.company_form.extension_costs') }}</h4>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="More Users"
            v-model="items.more_user"
            value=""
            :rules="[rules.range(0,99999)]"
          ></v-text-field>
          <form-error :errors="formErrors.more_user" />
          <v-text-field
            label="More Storage"
            v-model="items.more_storage"
            value=""
            :rules="[rules.range(0,99999)]"
          ></v-text-field>
          <form-error :errors="formErrors.more_storage" />
          <v-text-field
            label="More minutes"
            v-model="items.more_minutes"
            :rules="[rules.range(0,99999)]"
            value=""
          ></v-text-field>
          <form-error :errors="formErrors.more_minutes" />
          <v-text-field
            label="Additional GPU"
            v-model="items.more_gpus"
            :rules="[rules.range(0,99999)]"
            value=""
          ></v-text-field>
          <form-error :errors="formErrors.more_gpus" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Costs +1 User"
            v-model="items.cost_for_more_user"
            value=""
            :rules="[rules.range(0,99999)]"
          ></v-text-field>
          <v-text-field
            label="Cost +100 GB"
            v-model="items.cost_for_more_storage"
            value=""
            :rules="[rules.range(0,99999)]"
          ></v-text-field>
          <v-text-field
            label="Cost +1000 minutes"
            v-model="items.cost_for_more_minutes"
            :rules="[rules.range(0,99999)]"
            value=""
          ></v-text-field>
          <v-text-field
            label="Cost +1 GPU"
            v-model="items.cost_for_more_gpus"
            :rules="[rules.range(0,99999)]"
            value=""
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>

import FormError from '@/components/_common/FormError.vue';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';

export default {
  name: 'NewPricing',
  components: {
    FormError,
  },
  mixins: [
    FormValidationRulesMixin,
  ],
  props: {
    formErrors: {
      type: Object,
    },
    isEditing: {
      type: Boolean,
    },
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      monthMenu: false,
      filterMonth: new Date(),
    };
  },
  computed: {
    minDate() {
      const currentDate = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000);
      const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1,
        currentDate.getDate());
      return minDate.toISOString().substr(0, 10);
    },
  },
  created() {
    this.getTodayDate();
  },
  methods: {
    getTodayDate() {
      this.filterMonth = new Date();
      const year = this.filterMonth.getFullYear();
      const month = this.filterMonth.getMonth() + 2;
      this.filterMonth = `${year}-${month}`;
      this.items.valid_from = `${year}-${month < 10 ? `0${month}` : month}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
<style lang="scss">
  .remove-top-space .v-text-field--is-booted{
    padding-top: 0;
    margin-top: 0;
  }
  // disabled dates
  .v-date-picker-table
  button.v-btn.v-size--default.v-btn--flat.v-btn--text.v-btn--disabled.theme--light .v-btn__content{
    color: #bbbbbb!important;
  }
</style>
