function createCompanyPayment(customOrderId, order) {
  const data = {
    amount: order.amount,
    order_number: order.order_number,
    notes: order.notes,
  };
  return window.$http({
    url: `/api/v1/admin/company/${customOrderId}/payment`,
    method: 'post',
    data,
  });
}

export default createCompanyPayment;
