<template>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="itemsWithIconUrl"
          disable-sort
          :disable-pagination="true"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Models (CNN)</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.icon="{ item }">
            <img :src="item.iconUrl" style="width: 50px; height: 50px"
                 alt=""/>
          </template>
        </v-data-table>
      </v-col>
      <DialogConfirm ref="confirm"/>
    </v-row>
</template>

<script>
import DialogConfirm from '@/components/DialogConfirm.vue';
import getModels from '@/api/admin/settings/getModels';

export default {
  name: 'Models',
  components: {
    DialogConfirm,
  },
  data() {
    return {
      data: [],
      dialog: false,
      loading: true,
      headers: [
        { text: '', value: 'icon' },
        { text: 'Name', value: 'name' },
        { text: 'Steps (default)', value: 'steps' },
        { text: 'Learningrate (default)', value: 'learning_rate' },
      ],
    };
  },
  created() {
    this.fetchModels();
  },
  methods: {
    fetchModels() {
      getModels()
        .then((response) => {
          this.data = response.data;
          this.totalItems = response.data.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    itemsWithIconUrl() {
      return this.data.map(
        (item) => ({
          ...item,
          // eslint-disable-next-line global-require,import/no-dynamic-require
          iconUrl: require(`@/assets/icons/models/${item.icon}`),
        }),
      );
    },
  },
};
</script>
