function addInstance(instance) {
  const data = {
    instance_id: instance.instanceId,
    gpu_param: instance.gpuParameter,
  };
  return window.$http({
    url: '/api/v1/aws/ec2/instance',
    method: 'post',
    data,
  });
}

export default addInstance;
