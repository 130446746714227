function deleteFile(files, parentDir) {
  const data = {
    files,
    parentDir,
  };
  return window.$http({
    url: '/api/v1/filemanager/delete',
    method: 'delete',
    data,
  });
}

export default deleteFile;
